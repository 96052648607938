<template lang="pug">
#app
  td-loading-overlay(v-if="loading")
  Header(v-if="this.loggedIn")
  .container-fluid
    router-view(:key="$route.fullPath")

</template>

<script>
import Header from "./views/header/Header";
import EventBus from "./event-bus";
import { EmployeesComponent } from "@/lib/components/employees/EmployeesComponent.ts";
import TdLoadingOverlay from "@/views/partials/loading/td-loading-overlay.vue";
import { MailboxesComponent } from "@/lib/components/mailboxes/MailboxesComponent.ts";

export default {
  name: "App",
  components: { TdLoadingOverlay, Header },
  data() {
    return {
      loggedIn: this.$session.exists(),
      loading: false,
    };
  },
  mounted() {
    try {
      EventBus.$on(
        "changeLoadingState",
        function (loading) {
          this.loading = loading;
        }.bind(this)
      );
      EventBus.$on(
        "changeLoginState",
        function (loggedIn) {
          this.loggedIn = loggedIn;
          this.loadEmployee();
          this.loadMailbox();
        }.bind(this)
      );
    } catch (e) {
      console.error(e);
      this.$alert(e.message);
    }
  },
  methods: {
    async loadEmployee() {
      try {
        if (!this.loggedIn) {
          return;
        }
        const employeesComponent = new EmployeesComponent();
        const employee = await employeesComponent.getEmployeeByUserId(
          this.$apollo,
          this.$session.get("userId")
        );
        if (!employee) {
          return;
        }
        this.$session.set("employeeId", employee.Id);
      } catch (e) {
        console.error(e);
        this.$alert(e.message);
      }
    },
    async loadMailbox() {
      try {
        const mailboxesComponent = new MailboxesComponent();
        const mailbox = await mailboxesComponent.getMailboxByUserId(
          this.$apollo,
          this.$session.get("userId")
        );
        if (!mailbox) {
          return;
        }
        this.$session.set("mailboxId", mailbox.Id);
      } catch (e) {
        console.error(e);
        this.$alert(e.message);
      }
    },
  },
};
</script>
<style lang="scss">
@import "assets/styles/base";
</style>
