<template lang="pug">
  .user-search-select-list-item
    font-awesome-icon.mr-2(:icon="['fas', 'user']")
    span {{ user.FirstName }} {{ user.LastName }} <{{ user.Email }}>

</template>

<script>
export default {
  name: "UserSearchSelectListItem",
  props: {
    user: {
      type: Object,
      required: true,
    },
    deselect: {
      type: Function,
      required: false,
    },
  },
};
</script>

<style scoped></style>
