<template lang="pug">
  .container-fluid.p-5
    .row
      .col-12
        .section-headline Benachrichtigungen
      .col-12
        ul.nav.nav-tabs.mb-4
          li.nav-item
            a.nav-link.rounded-0.active(data-toggle='tab' href='#notificationsForMe' role='tab' aria-selected='true') für mich
          li.nav-item
            a.nav-link.rounded-0(data-toggle='tab' href='#notificationsByMe' role='tab' aria-selected='true' @click="areMyNotificationsVisible = true") von mir erstellt
        .tab-content
          #notificationsForMe.tab-pane.show.active
            notifications(
              :user-id="userId"
              :product-id="productId"
              :host-id="hostId"
              :open-notification="openNotificationDetailsModal"
            )

          #notificationsByMe.tab-pane
            notifications(
              v-if="areMyNotificationsVisible"
              :created-by="createdBy"
              :product-id="productId"
              :host-id="hostId"
              :open-notification="openNotificationDetailsModal"
            )

</template>

<script>
import Pagination from "@/views/partials/pagination.vue";
import NotificationsTable from "@/views/notifications/NotificationsTable.vue";
import NotificationDetailsModal from "@/views/notifications/NotificationDetailsModal.vue";
import Notifications from "@/views/notifications/Notifications.vue";

export default {
  name: "NotificationsModal",
  components: { Notifications, NotificationsTable, Pagination },
  props: {
    userId: {
      type: [String, Number],
      required: false,
    },
    hostId: {
      type: [String, Number],
      required: false,
    },
    productId: {
      type: [String, Number],
      required: false,
    },
    createdBy: {
      type: [String, Number],
      required: false,
    },
  },
  data() {
    return {
      areMyNotificationsVisible: false,
    };
  },
  methods: {
    openNotificationDetailsModal(notificationId, hostId, productId, date) {
      try {
        this.$modal.show(
          NotificationDetailsModal,
          {
            notificationId: notificationId,
            hostId: hostId,
            productId: productId,
            date: date,
          },
          {
            height: "auto",
            classes: ["rounded-0", "w-auto"],
          }
        );
        this.$emit("close");
      } catch (e) {
        console.error(e);
        this.$alert(e.message);
      }
    },
  },
};
</script>

<style scoped lang="scss">
.scrollable {
  max-height: 500px;
  width: 100%;
  height: 100%;
  overflow: hidden;
  overflow-y: auto;
}
</style>
