// @ts-ignore
import {BaseComponent} from "../BaseComponent.ts";
// @ts-ignore
import {NotificationTagAssignments} from "../../../graphql/notificationTagAssignments/NotificationTagAssignments.ts";

export class NotificationTagAssignmentsComponent extends BaseComponent {
    async createNotificationTagAssignment(apollo, notificationTagAssignment) {
        try {
            if (!apollo) {
                return {
                    IsSuccess: false,
                    Message: "Kein Apollo-Client gefunden.",
                };
            }
            if (!notificationTagAssignment) {
                return {
                    IsSuccess: false,
                    Message: "Keine Tag-Zuweisung gefunden.",
                };
            }
            const createdNotificationTagAssignment = await apollo.query({
                query: NotificationTagAssignments.Mutations.CreateNotificationTagAssignment,
                fetchPolicy: this.fetchPolicy,
                variables: {
                    notificationTagAssignment: notificationTagAssignment,
                },
            })
                .then(({data}) => data?.createNotificationTagAssignment)
                .catch((e) => {
                    console.error(e);
                    return {
                        IsSuccess: false,
                        Message: e.message,
                    };
                });
            return createdNotificationTagAssignment;
        } catch (e) {
            console.error(e);
            return {
                IsSuccess: false,
                Message: e.message,
            };
        }
    }

    async deleteNotificationTagAssignment(apollo, id) {
        try {
            if (!apollo) {
                return {
                    IsSuccess: false,
                    Message: "Kein Apollo-Client gefunden.",
                };
            }
            if (!id) {
                return {
                    IsSuccess: false,
                    Message: "Keine Tag-Id gefunden.",
                };
            }
            const deletedNotificationTagAssignment = await apollo.query({
                query: NotificationTagAssignments.Mutations.DeleteNotificationTagAssignment,
                fetchPolicy: this.fetchPolicy,
                variables: {
                    id: id,
                },
            })
                .then(({data}) => data?.deleteNotificationTagAssignment)
                .catch((e) => {
                    console.error(e);
                    return {
                        IsSuccess: false,
                        Message: e.message,
                    };
                });
            return deletedNotificationTagAssignment;
        } catch (e) {
            console.error(e);
            return {
                IsSuccess: false,
                Message: e.message,
            };
        }
    }

    async getNotificationTagAssignments(apollo, notificationId) {
        try {
            if (!apollo || !notificationId) {
                return undefined;
            }
            const notificationTagAssignments = await apollo.query({
                query: NotificationTagAssignments.Queries.NotificationTagAssignments,
                fetchPolicy: this.fetchPolicy,
                variables: {
                    notificationId: notificationId,
                },
            })
                .then(({data}) => data?.notificationTagAssignments)
                .catch((e) => {
                    console.error(e);
                    return undefined;
                });
            return notificationTagAssignments;
        } catch (e) {
            console.error(e);
            return undefined;
        }
    }
}
