import gql from 'graphql-tag';

export const NewsletterSuggestionTags = {
    Queries: {
        NewsletterSuggestionTag: gql`
        query newsletterSuggestionTag($id: ID) {
            newsletterSuggestionTag(id: $id) {
                Id
                Name
                Color
                CreatedBy
                CreatedAt
                UpdatedBy
                UpdatedAt
                IsActive
            }
        }`,
        NewsletterSuggestionTags: gql`
        query newsletterSuggestionTags($newsletterSuggestionId: ID, $isActive: Boolean, $offset: Int, $limit: Int) {
            newsletterSuggestionTags(newsletterSuggestionId: $newsletterSuggestionId, isActive: $isActive, offset: $offset, limit: $limit) {
                Id
                Name
                Color
                CreatedBy
                CreatedAt
                UpdatedBy
                UpdatedAt
                IsActive
            }
        }`,
        NewsletterSuggestionTagsCount: gql`
        query newsletterSuggestionTagsCount {
            newsletterSuggestionTagsCount
        }`,
    },
    Mutations: {
        SaveNewsletterSuggestionTag: gql`
        mutation saveNewsletterSuggestionTag($newsletterSuggestionTag: NewsletterSuggestionTagsInput) {
            saveNewsletterSuggestionTag(newsletterSuggestionTag: $newsletterSuggestionTag) {
                IsSuccess
                Message
                StringData
            }
        }`,
    }
};
