<template lang="pug">
  li.search-select-list-results-list-item(@click="_select")
    template(v-if="type == 'address'")
      font-awesome-icon.mr-2(:icon="['fas', 'user']")
      span {{ obj.FirstName }} {{ obj.LastName }}
      span(v-if="obj.Email") &nbsp;<{{ obj.Email }}>

    template(v-else-if="type == 'host'")
      font-awesome-icon.mr-2(:icon="['fas', 'house']")
      span {{ obj.Id }}: {{ obj.Name }}
      br
      span {{ obj.PAdresse4 }}, {{ obj.PPLZ }} {{ obj.PORT }} {{ obj.PLAND }}
      br
      span(v-if="obj.EMAIL1") &nbsp;<{{ obj.EMAIL1 }}>

    template(v-else-if="type == 'product'")
      product-search-select-list-results-list-item(
        :product="obj"
      )

    template(v-else-if="type == 'order'")
      order-search-select-list-results-list-item(
        :order="obj"
      )

    template(v-else-if="type == 'voucher'")
      voucher-search-select-list-results-list-item(
        :voucher="obj"
      )

    template(v-else-if="type == 'mail'")
      font-awesome-icon.mr-2(:icon="['fas', 'at']")
      span {{ obj.Id }}: {{ obj.MailFrom }}
      br
      span {{ obj.Subject }}

    template(v-else-if="type == 'molossCategory'")
      moloss-category-search-select-list-results-list-item(
        :moloss-category="obj"
      )

    template(v-else-if="type == 'molossItem'")
      moloss-item-search-select-list-results-list-item(
        :moloss-item="obj"
      )

    template(v-else-if="type == 'userGroup'")
      user-group-search-select-list-results-list-item(
        :user-group="obj"
      )

    template(v-else-if="type == 'user'")
      user-search-select-list-results-list-item(
        :user="obj"
      )

    template(v-else)
      span(v-if="typeof obj == 'object'") {{ JSON.stringify(obj) }}
      span(v-else) {{ obj }}

</template>

<script>
import OrderSearchSelectListResultsListItem from "@/views/selects/searchSelect/resultsListItems/OrderSearchSelectListResultsListItem.vue";
import VoucherSearchSelectListResultsListItem from "@/views/selects/searchSelect/resultsListItems/VoucherSearchSelectListResultsListItem.vue";
import MolossCategorySearchSelectListResultsListItem from "@/views/selects/searchSelect/resultsListItems/MolossCategorySearchSelectListResultsListItem.vue";
import ProductSearchSelectListResultsListItem from "@/views/selects/searchSelect/resultsListItems/ProductSearchSelectListResultsListItem.vue";
import UserGroupSearchSelectListResultsListItem from "@/views/selects/searchSelect/resultsListItems/UserGroupSearchSelectListResultsListItem.vue";
import UserSearchSelectListResultsListItem from "@/views/selects/searchSelect/resultsListItems/UserSearchSelectListResultsListItem.vue";
import MolossItemSearchSelectListResultsListItem from "@/views/selects/searchSelect/resultsListItems/MolossItemSearchSelectListResultsListItem.vue";

export default {
  name: "SearchSelectListResultsListItem",
  components: {
    MolossItemSearchSelectListResultsListItem,
    UserSearchSelectListResultsListItem,
    UserGroupSearchSelectListResultsListItem,
    ProductSearchSelectListResultsListItem,
    MolossCategorySearchSelectListResultsListItem,
    VoucherSearchSelectListResultsListItem,
    OrderSearchSelectListResultsListItem,
  },
  props: {
    obj: {
      type: [Object, String],
      required: true,
    },
    type: {
      type: String,
      required: false,
      default: "default",
    },
    field: {
      type: String,
      required: false,
    },
    select: {
      type: Function,
      required: true,
    },
  },
  methods: {
    _select() {
      try {
        if (!this.obj.field) {
          return this.select(this.obj);
        }
        this.select(this.obj[this.obj.field]);
      } catch (e) {
        console.error(e);
        this.$alert(e.message);
      }
    },
  },
};
</script>

<style scoped></style>
