<template lang="pug">
.deselect-button.d-flex
  a(@click="deselect(obj)" :disabled="isDisabled" :class="{ 'disabled': isDisabled }")
    font-awesome-icon(icon='trash')

</template>

<script>
export default {
  name: "DeselectButton",
  props: {
    obj: {
      type: [Object, String],
      required: true,
    },
    deselect: {
      type: Function,
      required: true,
    },
    isDisabled: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
}
</script>

<style scoped>

</style>
