import gql from "graphql-tag";

export const Hosts = {
    Queries: {
        Hosts: gql`
        query hosts($hostPoolId: ID, $filter: String, $isVisibleInItemListing: Boolean, $offset: Int, $limit: Int) {
            hosts(hostPoolId: $hostPoolId, filter: $filter, isVisibleInItemListing: $isVisibleInItemListing, offset: $offset, limit: $limit) {
                Id
                ProvisionFP
                Verkaufsgebuehren
                Match1
                PPLZ
                PORT
                PLAND
                RLAND
                PAdresse1
                PAdresse2
                PAdresse3
                PAdresse4
                RAdresse1
                RAdresse2
                RAdresse3
                RAdresse4
                RAdresse5
                RAdresse6
                RAdresse7
                RPLZ
                RORT
                Ansprechpartner
                TELEFON1
                TELEFON2
                TELEFAX1
                EMAIL1
                EMAIL2
                Match1
                Match2
                Internetadresse1
                Internetadresse2
                HTMLTitel
                HTMLSubtitel
                HTMLBlock01
                HTMLBlock02
                HTMLBlock03
                HTMLBlock04
                ThirdPartyId
                Stars
                CityRegion
                Description
                Name
                Longitude
                Latitude
                LinkVideo
                ShopEmailBenachrichtigungAb
                IsActiveTouriBookCustomer
                NotificationTimeoutTill
                UStIDNr
                AccountOwner
                IBAN
                BIC
                QuellCodeExtras
                DistributionId
                Info
            }
        }`,
        HostsCount: gql`
        query hostsCount($hostPoolId: ID, $filter: String, $isVisibleInItemListing: Boolean) {
            hostsCount(hostPoolId: $hostPoolId, filter: $filter, isVisibleInItemListing: $isVisibleInItemListing)
        }`,
        Host: gql`
        query host($id: ID) {
            host(id: $id) {
                Id
                ProvisionFP
                Verkaufsgebuehren
                Match1
                PPLZ
                PORT
                PLAND
                RLAND
                PAdresse1
                PAdresse2
                PAdresse3
                PAdresse4
                RAdresse1
                RAdresse2
                RAdresse3
                RAdresse4
                RAdresse5
                RAdresse6
                RAdresse7
                RPLZ
                RORT
                Ansprechpartner
                TELEFON1
                TELEFON2
                TELEFAX1
                EMAIL1
                EMAIL2
                Match1
                Match2
                Internetadresse1
                Internetadresse2
                HTMLTitel
                HTMLSubtitel
                HTMLBlock01
                HTMLBlock02
                HTMLBlock03
                HTMLBlock04
                ThirdPartyId
                Stars
                CityRegion
                Description
                Name
                Longitude
                Latitude
                LinkVideo
                ShopEmailBenachrichtigungAb
                IsActiveTouriBookCustomer
                NotificationTimeoutTill
                UStIDNr
                AccountOwner
                IBAN
                BIC
                QuellCodeExtras
                DistributionId
                Info
            }
        }`,
        HostsByHostPoolId: gql`
        query hostsByHostPoolId($hostPoolId: ID) {
            hostsByHostPoolId(hostPoolId: $hostPoolId) {
                Id
                ProvisionFP
                Verkaufsgebuehren
                Match1
                PPLZ
                PORT
                PLAND
                RLAND
                PAdresse1
                PAdresse2
                PAdresse3
                PAdresse4
                RAdresse1
                RAdresse2
                RAdresse3
                RAdresse4
                RAdresse5
                RAdresse6
                RAdresse7
                RPLZ
                RORT
                Ansprechpartner
                TELEFON1
                TELEFON2
                TELEFAX1
                EMAIL1
                EMAIL2
                Match1
                Match2
                Internetadresse1
                Internetadresse2
                HTMLTitel
                HTMLSubtitel
                HTMLBlock01
                HTMLBlock02
                HTMLBlock03
                HTMLBlock04
                ThirdPartyId
                Stars
                CityRegion
                Description
                Name
                Longitude
                Latitude
                LinkVideo
                LastOpenedAt
                LastOpenedBy
                NotificationTimeoutTill
                UStIDNr
                AccountOwner
                IBAN
                BIC
                QuellCodeExtras
                DistributionId
                Info
            }
        }`,
        LastOpenedHosts: gql`
        query lastOpenedHosts($userId: ID, $offset: Int, $limit: Int) {
            lastOpenedHosts(userId: $userId, offset: $offset, limit: $limit) {
                Id
                ProvisionFP
                Verkaufsgebuehren
                Match1
                PPLZ
                PORT
                PLAND
                RLAND
                PAdresse1
                PAdresse2
                PAdresse3
                PAdresse4
                RAdresse1
                RAdresse2
                RAdresse3
                RAdresse4
                RAdresse5
                RAdresse6
                RAdresse7
                RPLZ
                RORT
                Ansprechpartner
                TELEFON1
                TELEFON2
                TELEFAX1
                EMAIL1
                EMAIL2
                Match1
                Match2
                Internetadresse1
                Internetadresse2
                HTMLTitel
                HTMLSubtitel
                HTMLBlock01
                HTMLBlock02
                HTMLBlock03
                HTMLBlock04
                ThirdPartyId
                Stars
                CityRegion
                Description
                Name
                Longitude
                Latitude
                LinkVideo
                LastOpenedAt
                LastOpenedBy
                NotificationTimeoutTill
                UStIDNr
                AccountOwner
                IBAN
                BIC
                QuellCodeExtras
                DistributionId
                Info
            }
        }`,
        LastOpenedHostsCount: gql`
        query lastOpenedHostsCount($userId: ID) {
            lastOpenedHostsCount(userId: $userId)
        }`,
        HostsByUserId: gql`
        query hostsByUserId($userId: ID, $from: Date, $to: Date, $offset: Int, $limit: Int, $isActive: Boolean) {
            hostsByUserId(userId: $userId, from: $from, to: $to, offset: $offset, limit: $limit, isActive: $isActive) {
                Id
                ProvisionFP
                Verkaufsgebuehren
                Match1
                PPLZ
                PORT
                PLAND
                RLAND
                PAdresse1
                PAdresse2
                PAdresse3
                PAdresse4
                RAdresse1
                RAdresse2
                RAdresse3
                RAdresse4
                RAdresse5
                RAdresse6
                RAdresse7
                RPLZ
                RORT
                Ansprechpartner
                TELEFON1
                TELEFON2
                TELEFAX1
                EMAIL1
                EMAIL2
                Match1
                Match2
                Internetadresse1
                Internetadresse2
                HTMLTitel
                HTMLSubtitel
                HTMLBlock01
                HTMLBlock02
                HTMLBlock03
                HTMLBlock04
                ThirdPartyId
                Stars
                CityRegion
                Description
                Name
                Longitude
                Latitude
                LinkVideo
                NotificationTimeoutTill
                UStIDNr
                AccountOwner
                IBAN
                BIC
                QuellCodeExtras
                DistributionId
                Info
            }
        }`,
        HostsSalesValueByUserId: gql`
        query hostsSalesValueByUserId($userId: ID, $from: Date, $to: Date, $isActive: Boolean) {
            hostsSalesValueByUserId(userId: $userId, from: $from, to: $to, isActive: $isActive)
        }`,
        HostsCountByUserId: gql`
        query hostsCountByUserId($userId: ID, $from: Date, $to: Date, $isActive: Boolean) {
            hostsCountByUserId(userId: $userId, from: $from, to: $to, isActive: $isActive)
        }`,
        HostByProductContractId: gql`
        query hostByProductContractId($productContractId: ID) {
            hostByProductContractId(productContractId: $productContractId) {
                Id
                ProvisionFP
                Verkaufsgebuehren
                Match1
                PPLZ
                PORT
                PLAND
                RLAND
                PAdresse1
                PAdresse2
                PAdresse3
                PAdresse4
                RAdresse1
                RAdresse2
                RAdresse3
                RAdresse4
                RAdresse5
                RAdresse6
                RAdresse7
                RPLZ
                RORT
                Ansprechpartner
                TELEFON1
                TELEFON2
                TELEFAX1
                EMAIL1
                EMAIL2
                Match1
                Match2
                Internetadresse1
                Internetadresse2
                HTMLTitel
                HTMLSubtitel
                HTMLBlock01
                HTMLBlock02
                HTMLBlock03
                HTMLBlock04
                ThirdPartyId
                Stars
                CityRegion
                Description
                Name
                Longitude
                Latitude
                LinkVideo
                NotificationTimeoutTill
                UStIDNr
                AccountOwner
                IBAN
                BIC
                QuellCodeExtras
                DistributionId
                Info
            }
        }`,
        HostsByEmail: gql`
        query hostsByEmail($email: String, $offset: Int, $limit: Int) {
            hostsByEmail(email: $email, offset: $offset, limit: $limit) {
                Id
                ProvisionFP
                Verkaufsgebuehren
                Match1
                PPLZ
                PORT
                PLAND
                RLAND
                PAdresse1
                PAdresse2
                PAdresse3
                PAdresse4
                RAdresse1
                RAdresse2
                RAdresse3
                RAdresse4
                RAdresse5
                RAdresse6
                RAdresse7
                RPLZ
                RORT
                Ansprechpartner
                TELEFON1
                TELEFON2
                TELEFAX1
                EMAIL1
                EMAIL2
                Match1
                Match2
                Internetadresse1
                Internetadresse2
                HTMLTitel
                HTMLSubtitel
                HTMLBlock01
                HTMLBlock02
                HTMLBlock03
                HTMLBlock04
                ThirdPartyId
                Stars
                CityRegion
                Description
                Name
                Longitude
                Latitude
                LinkVideo
                NotificationTimeoutTill
                UStIDNr
                AccountOwner
                IBAN
                BIC
                QuellCodeExtras
                DistributionId
                Info
            }
        }`,
        HostsByProductStatusIds: gql`
        query hostsByProductStatusIds($productStatusIds: [ID], $userGuid: String, $offset: Int, $limit: Int) {
            hostsByProductStatusIds(productStatusIds: $productStatusIds, userGuid: $userGuid, offset: $offset, limit: $limit) {
                Id
                Name
                CityRegion
                CreatedAt
                Match1
                Match2
            }
        }`,
        HostsCountByProductStatusIds: gql`
        query hostsCountByProductStatusIds($productStatusIds: [ID], $userGuid: String) {
            hostsCountByProductStatusIds(productStatusIds: $productStatusIds, userGuid: $userGuid)
        }`,
    },
    Mutations: {
        SaveLastOpened: gql`
        mutation SaveLastOpened($hostId: ID, $userId: ID) {
            SaveLastOpened(hostId: $hostId, userId: $userId) {
                IsSuccess
                Message
            }
        }`,
        SaveHost: gql`
        mutation saveHost($host: HostsInput, $userId: ID) {
            saveHost(host: $host, userId: $userId) {
                IsSuccess
                Message
                StringData
            }
        }`,
    },
};
