import { render, staticRenderFns } from "./NotificationTag.vue?vue&type=template&id=40b1d587&scoped=true&lang=pug"
import script from "./NotificationTag.vue?vue&type=script&lang=js"
export * from "./NotificationTag.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "40b1d587",
  null
  
)

export default component.exports