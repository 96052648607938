// @ts-ignore
import {Stock} from "../../../graphql/stock/Stock.ts";
// @ts-ignore
import {BaseComponent} from "../BaseComponent.ts";

export class StockComponent extends BaseComponent {
    async getStock(apollo, stockTypeId, productId, platformId, cutoffDate = undefined) {
        try {
            if (!apollo || !stockTypeId || !productId || !platformId) {
                return undefined;
            }
            const stock = await apollo.query({
                query: Stock.Queries.Stock,
                fetchPolicy: this.fetchPolicy,
                variables: {
                    productId: productId,
                    stockTypeId: stockTypeId,
                    platformId: platformId,
                    cutoffDate: cutoffDate
                },
            })
                .then(({data}) => data?.stock)
                .catch((e) => {
                    console.error(e);
                    return undefined;
                });
            if (!stock) {
                return undefined;
            }
            return stock;
        } catch (e) {
            console.error(e);
            return undefined;
        }
    }
}
