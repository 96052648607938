export class BaseComponent {
    fetchPolicy;

    constructor(fetchPolicy = "no-cache") {
        try {
            this.fetchPolicy = fetchPolicy;
        } catch (e) {
            console.error(e);
        }
    }
}
