<template lang="pug">
.row.mt-2
  .col-12
    span(v-if="user") {{ user.FirstName }} {{ user.LastName }}:
    br
    span {{ notificationComment.Comment }} ({{ notificationComment.CreatedAt | formatDateTime }})
</template>

<script>
import { UsersComponent } from "@/lib/components/users/UsersComponent.ts";

export default {
  name: "NotificationComment",
  props: {
    notificationComment: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      user: undefined,
    };
  },
  async mounted() {
    try {
      this.loadUser();
    } catch (e) {
      console.error(e);
      this.$alert(e.message);
    }
  },
  methods: {
    async loadUser() {
      try {
        const usersComponent = new UsersComponent();
        const user = await usersComponent.getUser(
          this.$apollo,
          this.notificationComment.CreatedBy
        );
        if (!user) {
          return;
        }
        this.user = user;
      } catch (e) {
        console.error(e);
        this.$alert(e.message);
      }
    },
  },
};
</script>

<style scoped></style>
