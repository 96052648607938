<template lang="pug">
  .row
    .col-12
      .section-headline Verträge
    .col-6
      .d-flex
        .form-check(v-for="_productContractStatus in productContractStatus" :key="_productContractStatus.Id" :class="{ 'ml-2': productContractStatus.indexOf(_productContractStatus) > 0 }")
          input.form-check-input(type="checkbox" :id="_productContractStatus.Id" :value="_productContractStatus.Id" v-model="productContractStatusIds" @change="filterProductContracts")
          label.form-check-label(:for="_productContractStatus.Id") {{ _productContractStatus.Description }}
    .col-6
      .float-right
        .form-inline.mr-3
          .form-check
            input.form-check-input(type="radio" :id="'productContractTypeId-' + ProductContractTypesEnum.Contract" :value="ProductContractTypesEnum.Contract" v-model="productContractTypeId" name="productContractTypeId" @change="filterProductContracts")
            label.form-check-label(:for="'productContractTypeId-' + ProductContractTypesEnum.Contract") Vertrag
          .form-check.ml-2
            input.form-check-input(type="radio" :id="'productContractTypeId-' + ProductContractTypesEnum.Restock" :value="ProductContractTypesEnum.Restock" v-model="productContractTypeId" name="productContractTypeId" @change="filterProductContracts")
            label.form-check-label(:for="'productContractTypeId-' + ProductContractTypesEnum.Restock") Aufstockung
          .form-check.ml-2
            input.form-check-input(type="radio" :id="'productContractTypeId-' + ProductContractTypesEnum.Amendment" :value="ProductContractTypesEnum.Amendment" v-model="productContractTypeId" name="productContractTypeId" @change="filterProductContracts")
            label.form-check-label(:for="'productContractTypeId-' + ProductContractTypesEnum.Amendment") Änderungsvertrag
          .form-check.ml-2
            input.form-check-input(type="radio" :id="'productContractTypeId-' + ProductContractTypesEnum.FrameworkAgreement" :value="ProductContractTypesEnum.FrameworkAgreement" v-model="productContractTypeId" name="productContractTypeId" @change="filterProductContracts")
            label.form-check-label(:for="'productContractTypeId-' + ProductContractTypesEnum.FrameworkAgreement") Rahmenvertrag
    .col-12
      product-contracts-table(
        :product-contracts="productContracts"
        :host-id="hostId"
      )
      pagination(:overall-count="productContractsCount" :offset="offset" :limit="limit")

</template>

<script>
import EventBus from "../../event-bus";
import Pagination from "@/views/partials/pagination.vue";
import { ProductContractsComponent } from "@/lib/components/productContracts/ProductContractsComponent.ts";
import { ProductContractTypesEnum } from "@/utils/enums/productContractTypes/ProductContractTypesEnum.ts";
import ProductContractsTable from "@/views/productContracts/ProductContractsTable.vue";
import { ProductContractStatusEnum } from "@/utils/enums/productContractStatus/ProductContractStatusEnum.ts";
import { ProductContractStatusComponent } from "@/lib/components/productContractStatus/ProductContractStatusComponent.ts";
import SessionsComponent from "@/lib/components/sessions/SessionsComponent";

export default {
  name: "ProductContracts",
  props: {
    hostId: {
      type: String,
      required: false,
    },
    productId: {
      type: String,
      required: false,
    },
    userId: {
      type: String,
      required: false,
    },
  },
  components: {
    ProductContractsTable,
    Pagination,
  },
  data() {
    return {
      productContracts: [],
      productContractsCount: 0,
      productContractTypeId: ProductContractTypesEnum.Contract,

      offset: 0,
      limit: 15,

      productContractStatus: [],
      productContractStatusIds: [
        ProductContractStatusEnum.Draft,
        ProductContractStatusEnum.Sent,
        ProductContractStatusEnum.Confirmed,
        ProductContractStatusEnum.Rejected,
        ProductContractStatusEnum.Revoked,
        ProductContractStatusEnum.Replaced,
      ],

      ProductContractTypesEnum: ProductContractTypesEnum,
    };
  },
  async mounted() {
    try {
      const sessionsComponent = new SessionsComponent();
      sessionsComponent.handleQueryState(this);
      this.loadProductContracts();
      this.loadProductContractsCount();
      this.loadProductContractStatus();
      EventBus.$on(
        "updateContractsList",
        async function () {
          this.productContracts = [];
          this.loadProductContracts();
          this.loadProductContractsCount();
        }.bind(this)
      );
    } catch (e) {
      console.error(e);
      this.$alert(e.message);
    }
  },
  methods: {
    async filterProductContracts() {
      try {
        const query = {
          offset: 0,
          productContractStatusIds: this.productContractStatusIds,
          productContractTypeId: this.productContractTypeId,
        };
        const sessionsComponent = new SessionsComponent();
        sessionsComponent.changeQueryState(this, query);
      } catch (e) {
        console.log(e);
        this.$alert(e.message);
      }
    },
    async loadProductContracts() {
      try {
        const productContractsComponent = new ProductContractsComponent();
        EventBus.$emit("changeLoadingState", true);
        const productContracts =
          await productContractsComponent.getProductContracts(
            this.$apollo,
            this.hostId,
            this.productId,
            this.userId,
            undefined,
            this.productContractStatusIds,
            [this.productContractTypeId],
            this.offset,
            this.limit
          );
        EventBus.$emit("changeLoadingState", false);
        if (!productContracts) {
          return;
        }
        this.productContracts = productContracts;
      } catch (e) {
        console.log(e);
        this.$alert(e.message);
      }
    },
    async loadProductContractsCount() {
      try {
        const productContractsComponent = new ProductContractsComponent();
        const productContractsCount =
          await productContractsComponent.getProductContractsCount(
            this.$apollo,
            this.hostId,
            this.productId,
            this.userId,
            undefined,
            this.productContractStatusIds,
            this.productContractTypeId
          );
        if (productContractsCount == undefined) {
          return;
        }
        this.productContractsCount = productContractsCount;
      } catch (e) {
        console.log(e);
        this.$alert(e.message);
      }
    },
    async loadProductContractStatus() {
      try {
        const productContractStatusComponent =
          new ProductContractStatusComponent();
        const productContractStatus =
          await productContractStatusComponent.getProductContractStatus(
            this.$apollo
          );
        if (!productContractStatus) {
          return;
        }
        this.productContractStatus = productContractStatus;
      } catch (e) {
        console.error(e);
        this.$alert(e.message);
      }
    },
  },
};
</script>
