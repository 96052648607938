<template lang="pug">
  .user-group-search-select-list-item
    font-awesome-icon.mr-2(:icon="['fas', 'users']")
    span {{ userGroup.Name }}
    span(v-if="userGroup.Description") &nbsp;({{ userGroup.Description }})

</template>

<script>
export default {
  name: "UserGroupSearchSelectListItem",
  props: {
    userGroup: {
      type: Object,
      required: true,
    },
    deselect: {
      type: Function,
      required: false,
    },
  },
};
</script>

<style scoped></style>
