<template lang="pug">
  li.search-select-list-item
    span.font-weight-norma(:class="{ 'badge': isBadge, 'badge-secondary': isBadge, 'p-2': isBadge }")
      .d-flex.align-items-center.font-weight-normal
        template(v-if="type == 'address'")
          font-awesome-icon.mr-2(:icon="['fas', 'user']")
          span {{ obj.FirstName }} {{ obj.LastName }}
          span(v-if="obj.Email") &nbsp;<{{ obj.Email }}>

        template(v-else-if="type == 'host'")
          host-search-select-list-item(
            :host="obj"
          )

        template(v-else-if="type == 'product'")
          product-search-select-list-item(
            :product="obj"
          )

        template(v-else-if="type == 'order'")
          order-search-select-list-item(
            :order="obj"
          )

        template(v-else-if="type == 'voucher'")
          font-awesome-icon.mr-2(:icon="['fas', 'file']")
          span {{ obj.Id }}

        template(v-else-if="type == 'molossCategory'")
          moloss-category-search-select-list-item(
            :moloss-category="obj"
          )

        template(v-else-if="type == 'molossItem'")
          moloss-item-search-select-list-item(
            :moloss-item="obj"
          )

        template(v-else-if="type == 'userGroup'")
          user-group-search-select-list-item(
            :user-group="obj"
          )

        template(v-else-if="type == 'user'")
          user-search-select-list-item(
            :user="obj"
          )

        template(v-else)
          span(v-if="typeof obj == 'object'") {{ JSON.stringify(obj) }}
          span(v-else) {{ obj }}

        deselect-button.ml-2(:obj="obj" :deselect="_deselect" v-if="!isDisabled")

</template>

<script>
import { PaymentTypesEnum } from "@/utils/enums/paymentTypes/PaymentTypesEnum.ts";
import { OrderStatusEnum } from "@/utils/enums/orderStatus/OrderStatusEnum.ts";
import MolossCategorySearchSelectListItem from "@/views/selects/searchSelect/listItems/MolossCategorySearchSelectListItem.vue";
import OrderSearchSelectListItem from "@/views/selects/searchSelect/listItems/OrderSearchSelectListItem.vue";
import ProductSearchSelectListItem from "@/views/selects/searchSelect/listItems/ProductSearchSelectListItem.vue";
import UserGroupSearchSelectListItem from "@/views/selects/searchSelect/listItems/UserGroupSearchSelectListItem.vue";
import UserSearchSelectListItem from "@/views/selects/searchSelect/listItems/UserSearchSelectListItem.vue";
import HostSearchSelectListItem from "@/views/selects/searchSelect/listItems/HostSearchSelectListItem.vue";
import MolossItemSearchSelectListItem from "@/views/selects/searchSelect/listItems/MolossItemSearchSelectListItem.vue";
import DeselectButton from "@/views/selects/searchSelect/deselect/DeselectButton.vue";

export default {
  name: "SearchSelectListItem",
  components: {
    DeselectButton,
    MolossItemSearchSelectListItem,
    HostSearchSelectListItem,
    UserSearchSelectListItem,
    UserGroupSearchSelectListItem,
    OrderSearchSelectListItem,
    MolossCategorySearchSelectListItem,
    ProductSearchSelectListItem,
  },
  props: {
    obj: {
      type: [Object, String],
      required: true,
    },
    type: {
      type: String,
      required: false,
      default: "default",
    },
    isBadge: {
      type: Boolean,
      required: false,
      default: false,
    },
    deselect: {
      type: Function,
      required: false,
    },
    isDisabled: {
      type: Boolean,
      required: false,
    },
  },
  data() {
    return {
      PaymentTypesEnum: PaymentTypesEnum,
      OrderStatusEnum: OrderStatusEnum,
    };
  },
  methods: {
    _deselect() {
      try {
        if (this.isDisabled) {
          return;
        }
        if (!this.obj.field) {
          return this.deselect(this.obj);
        }
        this.deselect(this.obj[this.obj.field]);
      } catch (e) {
        console.error(e);
        this.$alert(e.message);
      }
    },
  },
};
</script>

<style scoped></style>
