import { render, staticRenderFns } from "./UserGroupSearchSelectListResultsListItem.vue?vue&type=template&id=2e05a21a&scoped=true&lang=pug"
import script from "./UserGroupSearchSelectListResultsListItem.vue?vue&type=script&lang=js"
export * from "./UserGroupSearchSelectListResultsListItem.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2e05a21a",
  null
  
)

export default component.exports