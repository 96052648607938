// @ts-ignore
import { NewsletterSuggestionTags } from "../../../graphql/newsletterSuggestionTags/NewsletterSuggestionTags.ts";

export class NewsletterSuggestionTagsComponent {
    async saveNewsletterSuggestionTag(apollo, newsletterSuggestionTag) {
        try {
            if (!apollo) {
                return {
                    IsSuccess: false,
                    Message: "Kein Apollo-Client gefunden.",
                };
            }
            if (!newsletterSuggestionTag) {
                return {
                    IsSuccess: false,
                    Message: "Kein Tag gefunden.",
                };
            }
            const savedNewsletterSuggestionTag = await apollo.mutate({
                mutation: NewsletterSuggestionTags.Mutations.SaveNewsletterSuggestionTag,
                fetchPolicy: "no-cache",
                variables: {
                    newsletterSuggestionTag: newsletterSuggestionTag,
                },
            })
                .then(({data}) => data?.saveNewsletterSuggestionTag)
                .catch((e) => {
                    console.error(e);
                    return {
                        IsSuccess: false,
                        Message: e.message,
                    };
                });
            if (!savedNewsletterSuggestionTag) {
                return {
                    IsSuccess: false,
                    Message: "Der Tag konnte nicht gespeichert werden.",
                };
            }
            return savedNewsletterSuggestionTag
        } catch (e) {
            console.error(e);
            return {
                IsSuccess: false,
                Message: e.message,
            };
        }
    }

    async getNewsletterSuggestionTag(apollo, id) {
        try {
            if (!apollo) {
                return undefined;
            }
            if (!id) {
                return undefined;
            }
            const newsletterSuggestionTag = await apollo.query({
                query: NewsletterSuggestionTags.Queries.NewsletterSuggestionTag,
                fetchPolicy: "no-cache",
                variables: {
                    id: id,
                },
            })
                .then(({data}) => data?.newsletterSuggestionTag)
                .catch((e) => {
                    console.error(e);
                    return undefined;
                });
            if (!newsletterSuggestionTag) {
                return undefined;
            }
            return newsletterSuggestionTag;
        } catch (e) {
            console.error(e);
            return undefined;
        }
    }

    async getNewsletterSuggestionTags(apollo, newsletterSuggestionId, isActive, offset, limit) {
        try {
            if (!apollo) {
                return undefined;
            }
            const newsletterSuggestionTags = await apollo.query({
                query: NewsletterSuggestionTags.Queries.NewsletterSuggestionTags,
                fetchPolicy: "no-cache",
                variables: {
                    newsletterSuggestionId: newsletterSuggestionId,
                    isActive: isActive,
                    offset: offset,
                    limit: limit,
                },
            })
                .then(({data}) => data?.newsletterSuggestionTags)
                .catch((e) => {
                    console.error(e);
                    return undefined;
                });
            if (!newsletterSuggestionTags) {
                return undefined;
            }
            return newsletterSuggestionTags;
        } catch (e) {
            console.error(e);
            return undefined;
        }
    }

    async getNewsletterSuggestionTagsCount(apollo) {
        try {
            if (!apollo) {
                return undefined;
            }
            const newsletterSuggestionTagsCount = await apollo.query({
                query: NewsletterSuggestionTags.Queries.NewsletterSuggestionTagsCount,
                fetchPolicy: "no-cache",
            })
                .then(({data}) => data?.newsletterSuggestionTagsCount)
                .catch((e) => {
                    console.error(e);
                    return undefined;
                });
            if (newsletterSuggestionTagsCount == undefined) {
                return undefined;
            }
            return newsletterSuggestionTagsCount;
        } catch (e) {
            console.error(e);
            return undefined;
        }
    }
}
