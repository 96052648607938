import gql from "graphql-tag";

export const NotificationTags = {
    Queries: {
        NotificationTags: gql`
        query notificationTags($isActive: Boolean, $isManualSelectable: Boolean, $offset: Int, $limit: Int) {
            notificationTags(isActive: $isActive, isManualSelectable: $isManualSelectable, offset: $offset, limit: $limit) {
                Id
                Name
                Color
                CreatedBy
                CreatedAt
                UpdatedBy
                UpdatedAt
                IsActive
                IsEditable
                IsNotificatable
                IsManualSelectable
            }
        }`,
        NotificationTagsCount: gql`
        query notificationTagsCount {
            notificationTagsCount
        }`,
        NotificationTag: gql`
        query notificationTag($id: ID, $name: String) {
            notificationTag(id: $id, name: $name) {
                Id
                Name
                Color
                CreatedBy
                CreatedAt
                UpdatedBy
                UpdatedAt
                IsActive
                IsEditable
                IsNotificatable
                IsManualSelectable
            }
        }`,
    },
    Mutations: {
        SaveNotificationTag: gql`
        mutation saveNotificationTag($notificationTag: NotificationTagsInput) {
            saveNotificationTag(notificationTag: $notificationTag) {
                IsSuccess
                Message
            }
        }`,
    },
};
