import gql from "graphql-tag";

export const NotificationTagAssignments = {
    Queries: {
        NotificationTagAssignments: gql`
        query notificationTagAssignments($notificationId: ID) {
            notificationTagAssignments(notificationId: $notificationId) {
                Id
                NotificationTagId
                NotificationId
                CreatedBy
                CreatedAt
            }
        }`,
    },
    Mutations: {
        CreateNotificationTagAssignment: gql`
        mutation createNotificationTagAssignment($notificationTagAssignment: NotificationTagAssignmentsInput) {
            createNotificationTagAssignment(notificationTagAssignment: $notificationTagAssignment) {
                IsSuccess
                Message
            }
        }`,
        DeleteNotificationTagAssignment: gql`
        mutation deleteNotificationTagAssignment($id: ID) {
            deleteNotificationTagAssignment(id: $id) {
                IsSuccess
                Message
            }
        }`,
    },
};
