import Vue from "vue";
import VueRouter from "vue-router";
import VueApollo from "vue-apollo";
import Home from "../views/Home.vue";
import Service from "@/views/Service";

Vue.use(VueRouter);
Vue.use(VueApollo);

const routes = [
  {
    path: "/",
    name: "Home",
    component: Home,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/marketing",
    name: "Marketing",
    component: () =>
      import(/* webpackCHunkName: "Marketing" */ "../views/Marketing"),
    children: [
      {
        path: "overview",
        name: "MarketingOverview",
        props: true,
        meta: {
          requiresAuth: true,
        },
        component: () =>
          import(
            /* webpackCHunkName: "NewslettersEditingList" */ "../views/marketing/index.vue"
          ),
      },
      {
        path: "newsletters",
        name: "NewsletterEditingsList",
        props: true,
        meta: {
          requiresAuth: true,
        },
        component: () =>
          import(
            /* webpackCHunkName: "NewslettersEditingList" */ "../views/newsletterEditings/NewslettersEditingList.vue"
          ),
      },
      {
        path: "newsletters/new",
        name: "NewsletterNew",
        props: true,
        meta: {
          requiresAuth: true,
        },
        component: () =>
          import(
            /* webpackCHunkName: "NewsletterNew" */ "../views/newsletterEditings/NewsletterEditingDetails"
          ),
      },
      {
        path: "newsletters/:newsletterId",
        name: "NewsletterDetails",
        props: true,
        meta: {
          requiresAuth: true,
        },
        component: () =>
          import(
            /* webpackCHunkName: "NewsletterDetails" */ "../views/newsletterEditings/NewsletterEditingDetails"
          ),
      },
      {
        path: "productAdvertisements",
        name: "ProductAdvertisementsList",
        props: true,
        meta: {
          requiresAuth: true,
        },
        component: () =>
          import(
            /* webpackCHunkName: "ProductAdvertisementsList" */ "../views/productAdvertisements/ProductAdvertisementsList.vue"
          ),
      },
    ],
  },
  {
    path: "/sales",
    name: "Sales",
    component: () => import(/* webpackCHunkName: "Sales" */ "../views/Sales"),
    meta: {
      requiresAuth: true,
    },
    children: [
      {
        path: "services",
        name: "ServicesList",
        meta: {
          requiresAuth: true,
        },
        component: () =>
          import(
            /* webpackCHunkName: "ServicesList" */ "../views/services/ServicesList.vue"
          ),
      },
      {
        path: "services/new",
        name: "ServiceNew",
        props: true,
        meta: {
          requiresAuth: true,
        },
        component: () =>
          import(
            /* webpackCHunkName: "ServiceDetails" */ "../views/services/ServiceDetails.vue"
          ),
      },
      {
        path: "services/:serviceId",
        name: "ServiceDetails",
        props: true,
        meta: {
          requiresAuth: true,
        },
        component: () =>
          import(
            /* webpackCHunkName: "ServiceDetails" */ "../views/services/ServiceDetails"
          ),
      },
      {
        path: "hostpools",
        name: "HostPoolsList",
        props: true,
        meta: {
          requiresAuth: true,
        },
        component: () =>
          import(
            /* webpackCHunkName: "HostPoolsList" */ "../views/hostPools/HostPoolsList"
          ),
      },
      {
        path: "hostpools/:HostPoolId",
        name: "HostPoolDetails",
        props: true,
        meta: {
          requiresAuth: true,
        },
        component: () =>
          import(
            /* webpackCHunkName: "HostPoolDetails" */ "../views/hostPools/HostPoolDetails"
          ),
      },
      {
        path: "hostpools/new",
        name: "HostPoolNew",
        props: true,
        meta: {
          requiresAuth: true,
        },
        component: () =>
          import(
            /* webpackCHunkName: "HostPoolNew" */ "../views/hostPools/HostPoolDetails.vue"
          ),
      },
      {
        path: "globalExclusionPeriods",
        name: "GlobalExclusionPeriodsList",
        props: true,
        meta: {
          requiresAuth: true,
        },
        component: () =>
          import(
            /* webpackCHunkName: "GlobalExclusionPeriodsList" */ "../views/globalExclusionPeriods/GlobalExclusionPeriodsList"
          ),
      },
      {
        path: "globalExclusionPeriods/:GlobalExclusionPeriodId",
        name: "GlobalExclusionPeriodsDetail",
        props: true,
        meta: {
          requiresAuth: true,
        },
        component: () =>
          import(
            /* webpackCHunkName: "GlobalExclusionPeriodsDetail" */ "../views/globalExclusionPeriods/GlobalExclusionPeriodsDetail"
          ),
      },
      {
        path: "globalExclusionPeriods/new",
        name: "GlobalExclusionPeriodsNew",
        props: true,
        meta: {
          requiresAuth: true,
        },
        component: () =>
          import(
            /* webpackCHunkName: "GlobalExclusionPeriodsNew" */ "../views/globalExclusionPeriods/GlobalExclusionPeriodsNew"
          ),
      },
      {
        path: "newsletters",
        name: "NewslettersList",
        props: true,
        meta: {
          requiresAuth: true,
        },
        component: () =>
          import(
            /* webpackCHunkName: "NewslettersList" */ "../views/newsletters/NewslettersList.vue"
          ),
      },
      {
        path: "newsletterSuggestions/:newsletterSuggestionId",
        name: "NewsletterSuggestionDetails",
        props: true,
        meta: {
          requiresAuth: true,
        },
        component: () =>
          import(
            /* webpackCHunkName: "NewsletterSuggestionDetails" */ "../views/newsletterSuggestions/NewsletterSuggestionDetails"
          ),
      },
      {
        path: "newsletterSuggestions/new",
        name: "NewsletterSuggestionNew",
        props: true,
        meta: {
          requiresAuth: true,
        },
        component: () =>
          import(
            /* webpackCHunkName: "NewsletterSuggestionDetails" */ "../views/newsletterSuggestions/NewsletterSuggestionDetails"
          ),
      },
      {
        path: "newsletterSuggestions",
        name: "NewsletterSuggestionsList",
        props: true,
        meta: {
          requiresAuth: true,
        },
        component: () =>
          import(
            /* webpackCHunkName: "NewsletterSuggestionsList" */ "../views/newsletterSuggestions/NewsletterSuggestionsList"
          ),
      },
      {
        path: "newsletterPlannings",
        name: "NewsletterPlannings",
        props: true,
        meta: {
          requiresAuth: true,
        },
        component: () =>
          import(
            /* webpackCHunkName: "NewsletterPlanningDetails" */ "../views/newsletterPlannings/NewsletterPlanningDetails"
          ),
      },
      {
        path: "newsletterSuggestionTags",
        name: "NewsletterSuggestionTagsList",
        props: true,
        meta: {
          requiresAuth: true,
        },
        component: () =>
          import(
            /* webpackCHunkName: "NewsletterSuggestionTagsList" */ "../views/newsletterSuggestionTags/NewsletterSuggestionTagsList.vue"
          ),
      },
      {
        path: "newsletterSuggestionTag/:newsletterSuggestionTagId",
        name: "NewsletterSuggestionTagDetails",
        props: true,
        meta: {
          requiresAuth: true,
        },
        component: () =>
          import(
            /* webpackCHunkName: "NewsletterSuggestionTagDetails" */ "../views/newsletterSuggestionTags/NewsletterSuggestionTagDetails.vue"
          ),
      },
      {
        path: "newsletterSuggestionTag/new",
        name: "NewsletterSuggestionTagNew",
        props: true,
        meta: {
          requiresAuth: true,
        },
        component: () =>
          import(
            /* webpackCHunkName: "NewsletterSuggestionTagNew" */ "../views/newsletterSuggestionTags/NewsletterSuggestionTagDetails.vue"
          ),
      },
      {
        path: "hotelChains/:hotelChainId",
        name: "HotelChainDetails",
        props: true,
        meta: {
          requiresAuth: true,
        },
        component: () =>
          import(
            /* webpackCHunkName: "HotelChainDetails" */ "../views/hotelChains/HotelChainDetails"
          ),
      },
      {
        path: "hotelChains/new",
        name: "HotelChainNew",
        props: true,
        meta: {
          requiresAuth: true,
        },
        component: () =>
          import(
            /* webpackCHunkName: "HotelChainDetails" */ "../views/hotelChains/HotelChainDetails"
          ),
      },
      {
        path: "hotelChains",
        name: "HotelChainsList",
        props: true,
        meta: {
          requiresAuth: true,
        },
        component: () =>
          import(
            /* webpackCHunkName: "HotelChainsList" */ "../views/hotelChains/HotelChainsList"
          ),
      },
    ],
  },
  {
    path: "/service",
    name: "Service",
    component: Service,
    meta: {
      requiresAuth: true,
    },
    children: [
      {
        path: "orders",
        name: "OrdersList",
        meta: {
          requiresAuth: true,
        },
        component: () =>
          import(
            /* webpackCHunkName: "OrdersList" */ "../views/orders/OrdersList"
          ),
      },
      {
        path: "orders/new",
        name: "OrderNew",
        meta: {
          requiresAuth: true,
        },
        component: () =>
          import(
            /* webpackCHunkName: "OrderDetails" */ "../views/orders/OrderDetails"
          ),
      },
      {
        path: "orders/:orderGuid",
        name: "OrderDetails",
        props: true,
        meta: {
          requiresAuth: true,
        },
        component: () =>
          import(
            /* webpackCHunkName: "OrderDetails" */ "../views/orders/OrderDetails.vue"
          ),
      },
      {
        path: "orders/overview/:orderId",
        name: "OrderOverview",
        props: true,
        meta: {
          requiresAuth: true,
        },
        component: () =>
          import(
            /* webpackCHunkName: "OrderOverview" */ "../views/orders/OrderOverview"
          ),
      },
      {
        path: "orderActions/:orderActionId",
        name: "OrderActionDetails",
        props: true,
        meta: {
          requiresAuth: true,
        },
        component: () =>
          import(
            /* webpackCHunkName: "OrderActionDetails" */ "../views/orderActions/OrderActionDetails"
          ),
      },
      {
        path: "emails",
        name: "EmailList",
        props: true,
        meta: {
          requiresAuth: true,
        },
        component: () =>
          import(
            /* webpackCHunkName: "EmailList" */ "../views/emails/EmailList"
          ),
      },
      {
        path: "emails/:hostEmailHistoryId",
        name: "EmailHistoriesDetails",
        props: true,
        meta: {
          requiresAuth: true,
        },
        component: () =>
          import(
            /* webpackCHunkName: "EmailHistoryDetails" */ "../views/emailHistories/EmailHistoryDetails.vue"
          ),
      },
      {
        path: "hostsEditingProgress",
        name: "ServiceHostsEditingProgress",
        props: true,
        meta: {
          requiresAuth: true,
        },
        component: () =>
          import(
            /* webpackCHunkName: "ServiceHostsEditingProgressList" */ "../views/host/hostEditing/HostEditingProgressList.vue"
          ),
      },
      {
        path: "searchCriteriaAreas",
        name: "SearchCriteriaAreas",
        props: true,
        meta: {
          requiresAuth: true,
        },
        component: () =>
          import(
            /* webpackCHunkName: "SearchCriteriaAreas" */ "../views/searchCriteriaAreas/SearchCriteriaAreasList.vue"
          ),
      },
      {
        path: "searchCriteriaArea/new",
        name: "SearchCriteriaAreaNew",
        props: true,
        meta: {
          requiresAuth: true,
        },
        component: () =>
          import(
            /* webpackCHunkName: "SearchCriteriaAreaDetails" */ "../views/searchCriteriaAreas/SearchCriteriaAreaDetails.vue"
          ),
      },
      {
        path: "searchCriteriaArea/:searchCriteriaAreaId",
        name: "SearchCriteriaAreaDetails",
        props: true,
        meta: {
          requiresAuth: true,
        },
        component: () =>
          import(
            /* webpackCHunkName: "SearchCriteriaAreaDetails" */ "../views/searchCriteriaAreas/SearchCriteriaAreaDetails.vue"
          ),
      },
      {
        path: "searchCriteriaSubAreas",
        name: "SearchCriteriaSubAreas",
        props: true,
        meta: {
          requiresAuth: true,
        },
        component: () =>
          import(
            /* webpackCHunkName: "SearchCriteriaSubAreas" */ "../views/searchCriteriaSubAreas/SearchCriteriaSubAreasList.vue"
          ),
      },
      {
        path: "searchCriteriaSubArea/new",
        name: "SearchCriteriaSubAreaNew",
        props: true,
        meta: {
          requiresAuth: true,
        },
        component: () =>
          import(
            /* webpackCHunkName: "SearchCriteriaSubAreaDetails" */ "../views/searchCriteriaSubAreas/SearchCriteriaSubAreaDetails.vue"
          ),
      },
      {
        path: "searchCriteriaSubArea/:searchCriteriaSubAreaId",
        name: "SearchCriteriaSubAreaDetails",
        props: true,
        meta: {
          requiresAuth: true,
        },
        component: () =>
          import(
            /* webpackCHunkName: "SearchCriteriaSubAreaDetails" */ "../views/searchCriteriaSubAreas/SearchCriteriaSubAreaDetails.vue"
          ),
      },
      {
        path: "transactions",
        name: "TransactionsList",
        props: true,
        meta: {
          requiresAuth: true,
        },
        component: () =>
          import(
            /* webpackCHunkName: "TransactionsList" */ "../views/transactions/TransactionsList"
          ),
      },
      {
        path: "transactionDetails/:transactionGuid",
        name: "TransactionDetails",
        props: true,
        meta: {
          requiresAuth: true,
        },
        component: () =>
          import(
            /* webpackCHunkName: "TransactionDetails" */ "../views/transactions/TransactionDetails.vue"
          ),
      },
      {
        path: "vouchers",
        name: "VouchersList",
        props: true,
        meta: {
          requiresAuth: true,
        },
        component: () =>
          import(
            /* webpackCHunkName: "VouchersList" */ "../views/vouchers/VouchersList"
          ),
      },
      {
        path: "vouchers/:voucherId",
        name: "VoucherDetails",
        props: true,
        meta: {
          requiresAuth: true,
        },
        component: () =>
          import(
            /* webpackCHunkName: "VouchersList" */ "../views/vouchers/VoucherDetails"
          ),
      },
      {
        path: "shopVoucher",
        name: "ShopVouchersList",
        props: true,
        meta: {
          requiresAuth: true,
        },
        component: () =>
          import(
            /* webpackCHunkName: "ShopVouchersList" */ "../views/shopVouchers/ShopVouchersList.vue"
          ),
      },
      {
        path: "shopVouchers/:shopVoucherId",
        name: "ShopVoucherDetails",
        props: true,
        meta: {
          requiresAuth: true,
        },
        component: () =>
          import(
            /* webpackCHunkName: "ShopVouchersList" */ "../views/shopVouchers/ShopVoucherDetails.vue"
          ),
      },
      {
        path: "complaints",
        name: "ComplaintsList",
        props: true,
        meta: {
          requiresAuth: true,
        },
        component: () =>
          import(
            /* webpackCHunkName: "HostComplaintsList" */ "../views/hostComplaints/HostComplaintsList.vue"
          ),
      },
      {
        path: "complaints/:hostComplaintId",
        name: "ComplaintDetails",
        props: true,
        meta: {
          requiresAuth: true,
        },
        component: () =>
          import(
            /* webpackCHunkName: "HostComplaintDetails" */ "../views/hostComplaints/HostComplaintDetails.vue"
          ),
      },
      {
        path: "complaints/new",
        name: "ComplaintNew",
        props: true,
        meta: {
          requiresAuth: true,
        },
        component: () =>
          import(
            /* webpackCHunkName: "HostComplaintDetails" */ "../views/hostComplaints/HostComplaintDetails.vue"
          ),
      },
    ],
  },
  {
    path: "/admin",
    name: "Admin",
    component: () => import(/* webpackCHunkName: "Admin" */ "../views/Admin"),
    children: [
      {
        path: "evaluations",
        name: "AdminEvaluations",
        props: true,
        meta: {
          requiresAuth: true,
        },
        component: () =>
          import(
            /* webpackCHunkName: "AdminEvaluations" */ "../views/evaluations/AdminEvaluations.vue"
          ),
      },
      {
        path: "userGroups",
        name: "UserGroupsList",
        props: true,
        meta: {
          requiresAuth: true,
        },
        component: () =>
          import(
            /* webpackCHunkName: "UserGroups" */ "../views/userGroups/UserGroupsList"
          ),
      },
      {
        path: "userGroups/:userGroupId",
        name: "UserGroupDetails",
        props: true,
        meta: {
          requiresAuth: true,
        },
        component: () =>
          import(
            /* webpackCHunkName: "UserGroupDetails" */ "../views/userGroups/UserGroupDetails"
          ),
      },
      {
        path: "userGroups/new",
        name: "UserGroupNew",
        props: true,
        meta: {
          requiresAuth: true,
        },
        component: () =>
          import(
            /* webpackCHunkName: "UserGroupNew" */ "../views/userGroups/UserGroupDetails"
          ),
      },
      {
        path: "productStatus",
        name: "ProductStatusList",
        props: true,
        meta: {
          requiresAuth: true,
        },
        component: () =>
          import(
            /* webpackCHunkName: "ProductStatusList" */ "../views/productStatus/ProductStatusList.vue"
          ),
      },
      {
        path: "productStatus/:productStatusId",
        name: "ProductStatusDetails",
        props: true,
        meta: {
          requiresAuth: true,
        },
        component: () =>
          import(
            /* webpackCHunkName: "ProductStatusDetails" */ "../views/productStatus/ProductStatusDetails.vue"
          ),
      },
      {
        path: "productStatus/new",
        name: "ProductStatusNew",
        props: true,
        meta: {
          requiresAuth: true,
        },
        component: () =>
          import(
            /* webpackCHunkName: "ProductStatusDetails" */ "../views/productStatus/ProductStatusDetails.vue"
          ),
      },
      {
        path: "productIntermediateStatus",
        name: "ProductIntermediateStatusList",
        props: true,
        meta: {
          requiresAuth: true,
        },
        component: () =>
          import(
            /* webpackCHunkName: "ProductIntermediateStatusList" */ "../views/productIntermediateStatus/ProductIntermediateStatusList.vue"
          ),
      },
      {
        path: "productIntermediateStatus/new",
        name: "ProductIntermediateStatusNew",
        props: true,
        meta: {
          requiresAuth: true,
        },
        component: () =>
          import(
            /* webpackCHunkName: "ProductIntermediateStatusDetails" */ "../views/productIntermediateStatus/ProductIntermediateStatusDetails.vue"
          ),
      },
      {
        path: "productIntermediateStatus/:productIntermediateStatusId",
        name: "ProductIntermediateStatusDetails",
        props: true,
        meta: {
          requiresAuth: true,
        },
        component: () =>
          import(
            /* webpackCHunkName: "ProductIntermediateStatusDetails" */ "../views/productIntermediateStatus/ProductIntermediateStatusDetails.vue"
          ),
      },
      {
        path: "globalSettings",
        name: "GlobalSettings",
        props: true,
        meta: {
          requiresAuth: true,
        },
        component: () =>
          import(
            /* webpackCHunkName: "GlobalSettings" */ "../views/globalSettings/GlobalSettings"
          ),
      },
      {
        path: "notification-tags",
        name: "NotificationTags",
        props: true,
        meta: {
          requiresAuth: true,
        },
        component: () =>
            import(
                /* webpackCHunkName: "NotificationTags" */ "../views/notificationTags/NotificationTags.vue"
                ),
      },
    ],
  },
  {
    path: "/productManagerUsers",
    name: "ProductManagerUsersList",
    meta: {
      requiresAuth: true,
    },
    component: () =>
      import(
        /* webpackCHunkName: "ProductManagerUsersList" */ "../views/users/ProductManagerUsersList"
      ),
  },
  {
    path: "/dashboardUsers",
    name: "DashboardUsersList",
    meta: {
      requiresAuth: true,
    },
    component: () =>
      import(
        /* webpackCHunkName: "DashboardUsersList" */ "../views/users/DashboardUsersList"
      ),
  },
  {
    path: "/users/:userId",
    name: "UserDetails",
    props: true,
    meta: {
      requiresAuth: true,
    },
    component: () =>
      import(
        /* webpackCHunkName: "UserDetails" */ "../views/users/UserDetails"
      ),
  },
  {
    path: "/users/new",
    name: "UserNew",
    props: true,
    meta: {
      requiresAuth: true,
    },
    component: () =>
      import(
        /* webpackCHunkName: "UserDetails" */ "../views/users/UserDetails"
      ),
  },
  {
    path: "/users/products/:userId",
    name: "UserProducts",
    props: true,
    meta: {
      requiresAuth: true,
    },
    component: () =>
      import(
        /* webpackCHunkName: "UserProducts" */ "../views/users/UserProducts.vue"
      ),
  },
  {
    path: "/mailbox/:mailboxId",
    name: "Mailbox",
    props: true,
    meta: {
      requiresAuth: true,
    },
    component: () =>
      import(/* webpackCHunkName: "Mailbox" */ "../views/mails/Mailbox.vue"),
    children: [
      {
        path: "folders/list",
        name: "MailboxFoldersList",
        props: true,
        meta: {
          requiresAuth: true,
        },
        component: () =>
          import(
            /* webpackCHunkName: "MailboxFoldersList" */ "../views/mailboxFolders/MailboxFoldersList.vue"
          ),
      },
      {
        path: "folders/details/new",
        name: "MailboxFolderNew",
        props: true,
        meta: {
          requiresAuth: true,
        },
        component: () =>
          import(
            /* webpackCHunkName: "MailboxFolderDetails" */ "../views/mailboxFolders/MailboxFolderDetails.vue"
          ),
      },
      {
        path: "folders/details/:mailboxFolderId",
        name: "MailboxFolderDetails",
        props: true,
        meta: {
          requiresAuth: true,
        },
        component: () =>
          import(
            /* webpackCHunkName: "MailboxFolderDetails" */ "../views/mailboxFolders/MailboxFolderDetails.vue"
          ),
      },
      {
        path: "folders/:mailboxFolderId/rules/:mailboxFolderRuleId",
        name: "MailboxFolderRuleDetails",
        props: true,
        meta: {
          requiresAuth: true,
        },
        component: () =>
          import(
            /* webpackCHunkName: "MailboxFolderRuleDetails" */ "../views/mailboxFolderRules/MailboxFolderRuleDetails.vue"
          ),
      },
      {
        path: "folders/:mailboxFolderId/rules/new",
        name: "MailboxFolderRuleNew",
        props: true,
        meta: {
          requiresAuth: true,
        },
        component: () =>
          import(
            /* webpackCHunkName: "MailboxFolderRuleDetails" */ "../views/mailboxFolderRules/MailboxFolderRuleDetails.vue"
          ),
      },
      {
        path: "folders/:mailboxFolderId",
        name: "MailboxFolderMails",
        props: true,
        meta: {
          requiresAuth: true,
        },
        component: () =>
          import(
            /* webpackCHunkName: "MailboxFolderMails" */ "../views/mails/MailboxFolderMails.vue"
          ),
      },
      {
        path: "mails/details/:mailId",
        name: "MailboxMailDetails",
        props: true,
        meta: {
          requiresAuth: true,
        },
        component: () =>
          import(/* webpackCHunkName: "Mail" */ "../views/mails/Mail.vue"),
      },
      {
        path: "mails/send/:mailId",
        name: "MailboxMailSend",
        props: true,
        meta: {
          requiresAuth: true,
        },
        component: () =>
          import(
            /* webpackCHunkName: "MailSend" */ "../views/mails/MailSend.vue"
          ),
      },
      {
        path: "mails/send",
        name: "MailboxMailSendNew",
        props: true,
        meta: {
          requiresAuth: true,
        },
        component: () =>
          import(
            /* webpackCHunkName: "MailSend" */ "../views/mails/MailSend.vue"
          ),
      },
    ],
  },
  {
    path: "/evaluations/:userGuid",
    name: "UserEvaluations",
    props: true,
    meta: {
      requiresAuth: true,
    },
    component: () =>
      import(
        /* webpackCHunkName: "UserEvaluations" */ "../views/evaluations/UserEvaluations"
      ),
  },
  {
    path: "/contracts/:userGuid",
    name: "UserContracts",
    props: true,
    meta: {
      requiresAuth: true,
    },
    component: () =>
      import(
        /* webpackCHunkName: "UserContracts" */ "../views/contracts/UserContracts.vue"
      ),
  },
  {
    path: "/hosts",
    name: "HostsList",
    meta: {
      requiresAuth: true,
    },
    component: () =>
      import(/* webpackCHunkName: "HostsList" */ "../views/host/HostsList"),
  },
  {
    path: "/hosts/new",
    name: "HostNew",
    meta: {
      requiresAuth: true,
    },
    component: () =>
      import(/* webpackCHunkName: "HostNew" */ "../views/host/HostDetails"),
  },
  {
    path: "/hosts/:hostId",
    name: "Host",
    props: true,
    meta: {
      requiresAuth: true,
    },
    component: () =>
      import(/* webpackCHunkName: "HostIndex" */ "../views/host/HostIndex.vue"),
    children: [
      {
        path: "details",
        name: "HostDetails",
        props: true,
        meta: {
          requiresAuth: true,
        },
        component: () =>
          import(
            /* webpackCHunkName: "HostDetails" */ "../views/host/HostDetails"
          ),
      },
      {
        path: "texts",
        name: "HostTexts",
        props: true,
        meta: {
          requiresAuth: true,
        },
        component: () =>
          import(
            /* webpackCHunkName: "HostTexts" */ "../views/host/HostTexts.vue"
          ),
      },
      {
        path: "users",
        name: "HostUsers",
        props: true,
        meta: {
          requiresAuth: true,
        },
        component: () =>
          import(
            /* webpackCHunkName: "HostUsers" */ "../views/host/HostUsers.vue"
          ),
      },
      {
        path: "hostPools",
        name: "HostHostPools",
        props: true,
        meta: {
          requiresAuth: true,
        },
        component: () =>
          import(
            /* webpackCHunkName: "HostHostPools" */ "../views/host/HostHostPools.vue"
          ),
      },
      {
        path: "holidaycheck",
        name: "HostHolidaycheck",
        props: true,
        meta: {
          requiresAuth: true,
        },
        component: () =>
          import(
            /* webpackCHunkName: "HostHolidaycheck" */ "../views/host/HostHolidaycheck.vue"
          ),
      },
      {
        path: "productDrafts",
        name: "HostProductDrafts",
        props: true,
        meta: {
          requiresAuth: true,
        },
        component: () =>
          import(
            /* webpackCHunkName: "HostProductDrafts" */ "../views/host/HostProductDrafts.vue"
          ),
      },
      {
        path: "productDrafts/:productDraftId",
        name: "ProductDraftDetails",
        props: true,
        meta: {
          requiresAuth: true,
        },
        component: () =>
          import(
            /* webpackCHunkName: "ProductDraft" */ "../views/productDrafts/ProductDraft.vue"
          ),
      },
      {
        path: "productDrafts/new",
        name: "ProductDraftNew",
        props: true,
        meta: {
          requiresAuth: true,
        },
        component: () =>
          import(
            /* webpackCHunkName: "ProductDraft" */ "../views/productDrafts/ProductDraft.vue"
          ),
      },
      {
        path: "searchCriterias",
        name: "HostSearchCriterias",
        props: true,
        meta: {
          requiresAuth: true,
        },
        component: () =>
          import(
            /* webpackCHunkName: "HostSearchCriterias" */ "../views/host/HostSearchCriterias.vue"
          ),
      },
      {
        path: "notes",
        name: "HostNotes",
        props: true,
        meta: {
          requiresAuth: true,
        },
        component: () =>
          import(
            /* webpackCHunkName: "HostNotes" */ "../views/host/HostNotes.vue"
          ),
      },
      {
        path: "products",
        name: "HostProductsList",
        props: true,
        meta: {
          requiresAuth: true,
        },
        component: () =>
          import(
            /* webpackCHunkName: "HostProductsList" */ "../views/products/HostProductsList.vue"
          ),
      },
      {
        path: "products/:productId",
        name: "ProductDetails",
        props: true,
        meta: {
          requiresAuth: true,
        },
        component: () =>
          import(
            /* webpackCHunkName: "ProductDetails" */ "../views/products/ProductDetails"
          ),
      },
      {
        path: "vouchers",
        name: "HostVouchers",
        props: true,
        meta: {
          requiresAuth: true,
        },
        component: () =>
          import(
            /* webpackCHunkName: "HostVouchers" */ "../views/host/HostVouchers.vue"
          ),
      },
      {
        path: "productContracts",
        name: "ProductContracts",
        props: true,
        meta: {
          requiresAuth: true,
        },
        component: () =>
          import(
            /* webpackCHunkName: "ProductContracts" */ "../views/host/HostContracts.vue"
          ),
      },
      {
        path: "productContracts/new",
        name: "ProductContractNew",
        props: true,
        meta: {
          requiresAuth: true,
        },
        component: () =>
          import(
            /* webpackCHunkName: "ProductContractDetails" */ "../views/productContracts/ProductContractDetails.vue"
          ),
      },
      {
        path: "productContracts/:productContractId",
        name: "ProductContractDetails",
        props: true,
        meta: {
          requiresAuth: true,
        },
        component: () =>
          import(
            /* webpackCHunkName: "ProductContractDetails" */ "../views/productContracts/ProductContractDetails.vue"
          ),
      },
      {
        path: "advertisements",
        name: "HostAdvertisements",
        props: true,
        meta: {
          requiresAuth: true,
        },
        component: () =>
          import(
            /* webpackCHunkName: "HostAdvertisements" */ "../views/host/advertisements/HostAdvertisements.vue"
          ),
      },
      {
        path: "exclusion-periods",
        name: "HostExclusionPeriods",
        props: true,
        meta: {
          requiresAuth: true,
        },
        component: () =>
          import(
            /* webpackCHunkName: "HostExclusionPeriods" */ "../views/host/exclusionPeriods/HostExclusionPeriods.vue"
          ),
      },
      {
        path: "productRestocks/new",
        name: "ProductRestockNew",
        props: true,
        meta: {
          requiresAuth: true,
        },
        component: () =>
          import(
            /* webpackCHunkName: "ProductRestockDetails" */ "../views/productRestocks/ProductRestockDetails"
          ),
      },
      {
        path: "productRestocks/:productContractId",
        name: "ProductRestockDetails",
        props: true,
        meta: {
          requiresAuth: true,
        },
        component: () =>
          import(
            /* webpackCHunkName: "ProductRestockDetails" */ "../views/productRestocks/ProductRestockDetails"
          ),
      },
      {
        path: "hostEmailHistories",
        name: "HostEmailHistoriesList",
        props: true,
        meta: {
          requiresAuth: true,
        },
        component: () =>
          import(
            /* webpackCHunkName: "HostEmailList" */ "../views/hostEmailHistories/HostEmailHistoriesList.vue"
          ),
      },
      {
        path: "hostEmailHistories/:hostEmailHistoryId",
        name: "HostEmailHistoryDetails",
        props: true,
        meta: {
          requiresAuth: true,
        },
        component: () =>
          import(
            /* webpackCHunkName: "HostEmailList" */ "../views/hostEmailHistories/HostEmailHistoryDetails.vue"
          ),
      },
      {
        path: "evaluations",
        name: "EvaluationDetails",
        props: true,
        meta: {
          requiresAuth: true,
        },
        component: () =>
          import(
            /* webpackCHunkName: "EvaluationDetails" */ "../views/evaluations/EvaluationDetails"
          ),
      },
      {
        path: "mails",
        name: "HostMails",
        props: true,
        meta: {
          requiresAuth: true,
        },
        component: () =>
          import(
            /* webpackCHunkName: "HostMails" */ "../views/mails/HostMails.vue"
          ),
      },
      {
        path: "moloss",
        name: "MolossHost",
        props: true,
        meta: {
          requiresAuth: true,
        },
        component: () =>
          import(
            /* webpackCHunkName: "MolossHost" */ "../views/host/MolossHost.vue"
          ),
      },
      {
        path: "systemfiles",
        name: "SystemFiles",
        props: true,
        meta: {
          requiresAuth: true,
        },
        component: () =>
          import(
            /* webpackCHunkName: "SystemFiles" */ "../views/host/HostSystemFiles.vue"
          ),
      },
      {
        path: "systemfiles/:systemFileId",
        name: "SystemFileDetails",
        props: true,
        meta: {
          requiresAuth: true,
        },
        component: () =>
          import(
            /* webpackCHunkName: "SystemFileDetails" */ "../views/systemFiles/SystemFileDetails"
          ),
      },
      {
        path: "migrations",
        name: "HostMigrations",
        props: true,
        meta: {
          requiresAuth: true,
        },
        component: () =>
          import(
            /* webpackCHunkName: "HostMigrations" */ "../views/host/HostMigrations.vue"
          ),
      },
      {
        path: "images",
        name: "Images",
        props: true,
        meta: {
          requiresAuth: true,
        },
        component: () =>
          import(
            /* webpackCHunkName: "HostImages" */ "../views/host/images/Images"
          ),
      },
      {
        path: "images/:FileName",
        name: "ImageDetails",
        props: true,
        meta: {
          requiresAuth: true,
        },
        component: () =>
          import(
            /* webpackCHunkName: "ImageDetails" */ "../views/host/images/ImageDetails"
          ),
      },
      {
        path: "hostImages",
        name: "HostImages",
        props: true,
        meta: {
          requiresAuth: true,
        },
        component: () =>
          import(
            /* webpackCHunkName: "HostImages" */ "../views/host/images/HostImages"
          ),
      },
      {
        path: "hostImagesSorting",
        name: "HostImagesSorting",
        props: true,
        meta: {
          requiresAuth: true,
        },
        component: () =>
          import(
            /* webpackCHunkName: "HostImagesSorting" */ "../views/host/images/HostImagesSorting"
          ),
      },
      {
        path: "copyHostImageUrls",
        name: "CopyHostImageUrls",
        props: true,
        meta: {
          requiresAuth: true,
        },
        component: () =>
          import(
            /* webpackCHunkName: "HostImagesSorting" */ "../views/host/images/CopyHostImageUrls"
          ),
      },
      {
        path: "hostComplaints",
        name: "HostComplaintsList",
        props: true,
        meta: {
          requiresAuth: true,
        },
        component: () =>
          import(
            /* webpackCHunkName: "HostComplaintsList" */ "../views/hostComplaints/HostComplaintsList.vue"
          ),
      },
      {
        path: "hostComplaints/:hostComplaintId",
        name: "HostComplaintDetails",
        props: true,
        meta: {
          requiresAuth: true,
        },
        component: () =>
          import(
            /* webpackCHunkName: "HostComplaintDetails" */ "../views/hostComplaints/HostComplaintDetails.vue"
          ),
      },
      {
        path: "hostComplaints/new",
        name: "HostComplaintNew",
        props: true,
        meta: {
          requiresAuth: true,
        },
        component: () =>
          import(
            /* webpackCHunkName: "HostComplaintDetails" */ "../views/hostComplaints/HostComplaintDetails.vue"
          ),
      },
      {
        path: "hostServiceInfoTexts",
        name: "HostServiceInfoTextsList",
        props: true,
        meta: {
          requiresAuth: true,
        },
        component: () =>
          import(
            /* webpackCHunkName: "HostServiceInfoTextsList" */ "../views/hostServiceInfoTexts/HostServiceInfoTextsList.vue"
          ),
      },
      {
        path: "hostServiceInfoTexts/:hostServiceInfoTextId",
        name: "HostServiceInfoTextDetails",
        props: true,
        meta: {
          requiresAuth: true,
        },
        component: () =>
          import(
            /* webpackCHunkName: "HostServiceInfoTextDetails" */ "../views/hostServiceInfoTexts/HostServiceInfoTextDetails.vue"
          ),
      },
      {
        path: "hostServiceInfoTexts/new",
        name: "HostServiceInfoTextNew",
        props: true,
        meta: {
          requiresAuth: true,
        },
        component: () =>
          import(
            /* webpackCHunkName: "HostServiceInfoTextDetails" */ "../views/hostServiceInfoTexts/HostServiceInfoTextDetails.vue"
          ),
      },
      {
        path: "sellingPlatforms",
        name: "HostSellingPlatforms",
        props: true,
        meta: {
          requiresAuth: true,
        },
        component: () =>
          import(
            /* webpackCHunkName: "HostSellingPlatforms" */ "../views/sellingPlatforms/HostSellingPlatforms.vue"
          ),
      },
    ],
  },
  {
    path: "/moloss",
    name: "Moloss",
    component: () =>
      import(/* webpackCHunkName: "Moloss" */ "../views/moloss/Moloss.vue"),
    meta: {
      requiresAuth: true,
    },
    children: [
      {
        path: "molossCategories",
        name: "MolossCategoriesList",
        props: true,
        meta: {
          requiresAuth: true,
        },
        component: () =>
          import(
            /* webpackCHunkName: "MolossCategoriesList" */ "../views/moloss/molossCategories/MolossCategoriesList.vue"
          ),
      },
      {
        path: "molossCategories/:molossCategoryId",
        name: "MolossCategoryDetails",
        props: true,
        meta: {
          requiresAuth: true,
        },
        component: () =>
          import(
            /* webpackCHunkName: "MolossCategoryDetails" */ "../views/moloss/molossCategories/MolossCategoryDetails.vue"
          ),
      },
      {
        path: "molossCategories/new",
        name: "MolossCategoryNew",
        props: true,
        meta: {
          requiresAuth: true,
        },
        component: () =>
          import(
            /* webpackCHunkName: "MolossCategoryDetails" */ "../views/moloss/molossCategories/MolossCategoryDetails.vue"
          ),
      },
      {
        path: "molossCategorySliderItems",
        name: "MolossCategorySliderItems",
        props: true,
        meta: {
          requiresAuth: true,
        },
        component: () =>
          import(
            /* webpackCHunkName: "MolossCategorySliderItems" */ "../views/moloss/molossCategorySliderItems/MolossCategorySliderItems.vue"
          ),
      },
      {
        path: "molossCategorySliderItems/:molossCategorySliderItemId",
        name: "MolossCategorySliderItemDetails",
        props: true,
        meta: {
          requiresAuth: true,
        },
        component: () =>
          import(
            /* webpackCHunkName: "MolossCategorySliderItemDetails" */ "../views/moloss/molossCategorySliderItems/MolossCategorySliderItemDetails.vue"
          ),
      },
      {
        path: "molossCategorySliderItems/new",
        name: "MolossCategorySliderItemNew",
        props: true,
        meta: {
          requiresAuth: true,
        },
        component: () =>
          import(
            /* webpackCHunkName: "MolossCategorySliderItemDetails" */ "../views/moloss/molossCategorySliderItems/MolossCategorySliderItemDetails.vue"
          ),
      },
      {
        path: "molossOrders",
        name: "MolossOrdersList",
        meta: {
          requiresAuth: true,
        },
        component: () =>
          import(
            /* webpackCHunkName: "MolossOrdersList" */ "../views/moloss/molossOrders/MolossOrdersList"
          ),
      },
      {
        path: "molossOrders/new",
        name: "MolossOrdersNew",
        meta: {
          requiresAuth: true,
        },
        component: () =>
          import(
            /* webpackCHunkName: "MolossOrdersNew" */ "../views/moloss/molossOrders/MolossOrdersNew"
          ),
      },
      {
        path: "molossOrders/:molossOrderId",
        name: "MolossOrderDetails",
        props: true,
        meta: {
          requiresAuth: true,
        },
        component: () =>
          import(
            /* webpackCHunkName: "MolossOrdersNew" */ "../views/moloss/molossOrders/MolossOrderDetails"
          ),
      },
      {
        path: "molossSelectionVouchers",
        name: "MolossSelectionVouchersList",
        meta: {
          requiresAuth: true,
        },
        component: () =>
          import(
            /* webpackCHunkName: "MolossSelectionVouchersList" */ "../views/moloss/molossSelectionVouchers/MolossSelectionVouchersList.vue"
          ),
      },
      {
        path: "molossSelectionVouchers/:molossSelectionVoucherId",
        name: "MolossSelectionVoucherDetails",
        props: true,
        meta: {
          requiresAuth: true,
        },
        component: () =>
          import(
            /* webpackCHunkName: "MolossSelectionVoucherDetails" */ "../views/moloss/molossSelectionVouchers/MolossSelectionVoucherDetails.vue"
          ),
      },
      {
        path: "molossHighlightOfTheWeek",
        name: "MolossHighlightOfTheWeek",
        props: true,
        meta: {
          requiresAuth: true,
        },
        component: () =>
          import(
            /* webpackCHunkName: "MolossOrderDiscountCodesList" */ "../views/moloss/molossHighlightOfTheWeek/MolossHighlightOfTheWeek.vue"
          ),
      },
      {
        path: "molossOrderDiscountCodes",
        name: "MolossOrderDiscountCodesList",
        props: true,
        meta: {
          requiresAuth: true,
        },
        component: () =>
          import(
            /* webpackCHunkName: "MolossOrderDiscountCodesList" */ "../views/moloss/molossOrderDiscountCodes/MolossOrderDiscountCodesList.vue"
          ),
      },
      {
        path: "molossOrderDiscountCodes/:molossOrderDiscountCodeId",
        name: "MolossOrderDiscountCodeDetails",
        props: true,
        meta: {
          requiresAuth: true,
        },
        component: () =>
          import(
            /* webpackCHunkName: "MolossOrderDiscountCodeDetails" */ "../views/moloss/molossOrderDiscountCodes/MolossOrderDiscountCodeDetails.vue"
          ),
      },
      {
        path: "molossOrderDiscountCodes/new",
        name: "MolossOrderDiscountCodeNew",
        props: true,
        meta: {
          requiresAuth: true,
        },
        component: () =>
          import(
            /* webpackCHunkName: "MolossOrderDiscountCodeNew" */ "../views/moloss/molossOrderDiscountCodes/MolossOrderDiscountCodeDetails.vue"
          ),
      },
      {
        path: "molossCampaignTypes",
        name: "MolossCampaignTypesList",
        props: true,
        meta: {
          requiresAuth: true,
        },
        component: () =>
          import(
            /* webpackCHunkName: "MolossCampaignTypesList" */ "../views/moloss/molossCampaignTypes/MolossCampaignTypesList.vue"
          ),
      },
      {
        path: "molossCampaignTypes/:molossCampaignTypeId",
        name: "MolossCampaignTypeDetails",
        props: true,
        meta: {
          requiresAuth: true,
        },
        component: () =>
          import(
            /* webpackCHunkName: "MolossCampaignTypeDetails" */ "../views/moloss/molossCampaignTypes/MolossCampaignTypeDetails.vue"
          ),
      },
      {
        path: "molossCampaignTypes/new",
        name: "MolossCampaignTypeNew",
        props: true,
        meta: {
          requiresAuth: true,
        },
        component: () =>
          import(
            /* webpackCHunkName: "MolossCampaignTypeDetails" */ "../views/moloss/molossCampaignTypes/MolossCampaignTypeDetails.vue"
          ),
      },
    ],
  },
  {
    path: "/products",
    name: "ProductsList",
    meta: {
      requiresAuth: true,
    },
    component: () =>
      import(
        /* webpackCHunkName: "ProductsList" */ "../views/products/ProductsList"
      ),
  },
  {
    path: "/notifications",
    name: "NotificationsList",
    props: true,
    meta: {
      requiresAuth: true,
    },
    component: () =>
      import(
        /* webpackCHunkName: "NotificationsList" */ "../views/notifications/NotificationsList.vue"
      ),
  },
  {
    path: "/appointments",
    name: "AppointmentsList",
    props: true,
    meta: {
      requiresAuth: true,
    },
    component: () =>
      import(
        /* webpackCHunkName: "AppointmentsList" */ "../views/appointments/AppointmentsList.vue"
      ),
  },
  {
    path: "/appointments/new",
    name: "AppointmentNew",
    props: true,
    meta: {
      requiresAuth: true,
    },
    component: () =>
      import(
        /* webpackCHunkName: "AppointmentDetails" */ "../views/appointments/AppointmentDetails.vue"
      ),
  },
  {
    path: "/appointments/:appointmentId",
    name: "AppointmentDetails",
    props: true,
    meta: {
      requiresAuth: true,
    },
    component: () =>
      import(
        /* webpackCHunkName: "AppointmentDetails" */ "../views/appointments/AppointmentDetails.vue"
      ),
  },
  {
    path: "/login",
    name: "Login",
    component: () => import(/* webpackChunkName: "Login" */ "../views/Login"),
  },
  {
    path: "/404",
    alias: "*",
    name: "NotFound",
    component: () =>
      import(/* webpackChunkName: "NotFound" */ "../views/NotFound"),
  },
];

const router = new VueRouter({
  routes,
  linkExactActiveClass: "active",
});

const moment = require("moment");
router.beforeEach((to, from, next) => {
  if (to.name == "Login") {
    return next();
  }
  // needs to login
  if (
    !router.app.$session.exists() ||
    moment().isAfter(router.app.$session.get("validTill"))
  ) {
    router.app.$session.destroy();
    return next({
      name: "Login",
      query: { redirect: to.fullPath },
    });
  }
  next();
});
router.onError((error) => {
  if (/loading chunk \d* failed./i.test(error.message) && navigator.onLine) {
    window.location.reload();
  }
}); // onError
export default router;
