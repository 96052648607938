// @ts-ignore
import {Users} from "../../../graphql/users/Users.ts";

export class UsersComponent {
    async getUsers(apollo, hostPoolIds, filter, offset, limit) {
        try {
            if (!apollo || ((!hostPoolIds || !Array.isArray(hostPoolIds) || !hostPoolIds.length) && !filter && !offset && !limit)) {
                return undefined;
            }
            const users = await apollo.query({
                query: Users.Queries.Users,
                variables: {
                    hostPoolIds: hostPoolIds,
                    filter: filter,
                    offset: offset,
                    limit: limit,
                }
            })
                .then(({data}) => data?.users)
                .catch((e) => {
                    console.error(e);
                    return undefined;
                })
            return users;
        } catch (e) {
            console.error(e);
            return undefined;
        }
    }

    async saveUser(apollo, user, permissionIds, hostIds) {
        try {
            if (!apollo) {
                return {
                    IsSuccess: false,
                    Message: "Kein Apollo-Client gefunden.",
                };
            }
            if (!user) {
                return {
                    IsSuccess: false,
                    Message: "Kein Benutzer gefunden."
                };
            }
            const savedUser = await apollo.mutate({
                mutation: Users.Mutations.SaveUser,
                fetchPolicy: "no-cache",
                variables: {
                    user: user,
                    permissionIds: permissionIds,
                    hostIds: hostIds,
                },
            })
                .then(({data}) => data?.saveUser)
                .catch((e) => {
                    console.error(e);
                    return {
                        IsSuccess: false,
                        Message: e.message,
                    };
                });
            if (!savedUser) {
                return {
                    IsSuccess: false,
                    Message: "Der Benutzer konnte nicht gespeichert werden.",
                };
            }
            return savedUser;
        } catch (e) {
            console.error(e);
            return {
                IsSuccess: false,
                Message: e.message,
            };
        }
    }

    async getUser(apollo, id) {
        try {
            if (!apollo) {
                return undefined;
            }
            if (!id) {
                return undefined;
            }
            const user = await apollo
                .query({
                    query: Users.Queries.User,
                    variables: {
                        id: id,
                    },
                })
                .then(({data}) => data?.user)
                .catch((e) => {
                    console.error(e);
                    return undefined;
                });
            if (!user) {
                return undefined;
            }
            return user;
        } catch (e) {
            console.error(e);
            return undefined;
        }
    }

    async getUserByEmail(apollo, email) {
        try {
            if (!apollo || !email) {
                return undefined;
            }
            const user = await apollo.query({
                query: Users.Queries.UserByEmail,
                fetchPolicy: "no-cache",
                variables: {
                    email: email,
                },
            })
                .then(({data}) => data?.userByEmail)
                .catch((e) => {
                    console.error(e);
                    return undefined;
                });
            return user;
        } catch (e) {
            console.error(e);
            return undefined;
        }
    }

    async getUsersByHostId(apollo, hostId) {
        try {
            if (!apollo) {
                return undefined;
            }
            if (!hostId) {
                return undefined;
            }
            const users = await apollo
                .query({
                    query: Users.Queries.UsersByHostId,
                    fetchPolicy: "no-cache",
                    variables: {
                        hostId: hostId,
                    },
                })
                .then(({data}) => data?.usersByHostId)
                .catch((e) => {
                    console.error(e);
                    return undefined;
                });
            if (!users) {
                return undefined;
            }
            return users;
        } catch (e) {
            console.error(e);
            return undefined;
        }
    }

    async getProductManagerUsers(apollo, filter, offset, limit) {
        try {
            if (!apollo) {
                return undefined;
            }
            const users = await apollo.query({
                query: Users.Queries.ProductManagerUsers,
                fetchPolicy: "no-cache",
                variables: {
                    filter: filter,
                    offset: offset,
                    limit: limit,
                },
            })
                .then(({data}) => data?.productManagerUsers)
                .catch((e) => {
                    console.error(e);
                    return undefined;
                });
            if (!users) {
                return undefined;
            }
            return users;
        } catch (e) {
            console.error(e);
            return undefined;
        }
    }
}
