// @ts-ignore
import {BaseComponent} from "../BaseComponent.ts";
// @ts-ignore
import {NotificationTags} from "../../../graphql/notificationTags/NotificationTags.ts";

export class NotificationTagsComponent extends BaseComponent {
    async getNotificationTags(apollo, isActive, isManualSelectable, offset, limit) {
        try {
            if (!apollo) {
                return undefined;
            }
            const notificationTags = await apollo.query({
                query: NotificationTags.Queries.NotificationTags,
                fetchPolicy: this.fetchPolicy,
                variables: {
                    isActive: isActive,
                    isManualSelectable: isManualSelectable,
                    offset: offset,
                    limit: limit,
                },
            })
                .then(({data}) => data?.notificationTags)
                .catch((e) => {
                    console.error(e);
                    return undefined;
                });
            return notificationTags;
        } catch (e) {
            console.error(e);
            return undefined;
        }
    }

    async getNotificationTagsCount(apollo) {
        try {
            if (!apollo) {
                return undefined;
            }
            const notificationTagsCount = await apollo.query({
                query: NotificationTags.Queries.NotificationTagsCount,
                fetchPolicy: this.fetchPolicy,
            })
                .then(({data}) => data?.notificationTagsCount)
                .catch((e) => {
                    console.error(e);
                    return undefined;
                });
            return notificationTagsCount;
        } catch (e) {
            console.error(e);
            return undefined;
        }
    }

    async getNotificationTag(apollo, id, name) {
        try {
            if (!apollo) {
                return undefined;
            }
            if (!id && !name) {
                return undefined;
            }
            const notificationTag = await apollo.query({
                query: NotificationTags.Queries.NotificationTag,
                fetchPolicy: this.fetchPolicy,
                variables: {
                    id: id,
                    name: name,
                }
            })
                .then(({data}) => data?.notificationTag)
                .catch((e) => {
                    console.error(e);
                    return undefined;
                });
            return notificationTag;
        } catch (e) {
            console.error(e);
            return undefined;
        }
    }

    async saveNotificationTag(apollo, notificationTag) {
        try {
            if (!apollo) {
                return {
                    IsSuccess: false,
                    Message: "Kein Apollo-Client gefunden.",
                };
            }
            if (!notificationTag) {
                return {
                    IsSuccess: false,
                    Message: "Kein Tag gefunden.",
                };
            }
            const savedNotificationTag = await apollo.mutate({
                mutation: NotificationTags.Mutations.SaveNotificationTag,
                fetchPolicy: this.fetchPolicy,
                variables: {
                    notificationTag: notificationTag,
                },
            })
                .then(({data}) => data?.saveNotificationTag)
                .catch((e) => {
                    console.error(e);
                    return {
                        IsSuccess: false,
                        Message: e.message,
                    };
                });
            return savedNotificationTag;
        } catch (e) {
            console.error(e);
            return undefined;
        }
    }
}
