<template lang="pug">
  .row
    .col-12.mt-2.mb-2
      CalendarPlugin(:events="calendarEntries")

    .col-12.mt-2(v-if="permissions.find(p => p.Id == PermissionsEnum.Admin)")
      .card
        .card-header.text-center
          a.p-1.h4(v-b-toggle="'dailySales'") Tagesumsatz
        b-collapse(id="dailySales" v-model="areDailySalesVisible")
          .card-body(v-if="areDailySalesVisible")
            total-sales-evaluations(:start="moment().startOf('day').toDate()" :end="moment().endOf('day').toDate()" :init-loading="true" init-display-unit="days")

    .col-12.mt-2(v-if="services && Array.isArray(services) && services.length")
      .card
        .card-header
          .section-headline Leistungen
        .card-body
          services-table(:services="services" :load-services="loadServices")
          pagination(:overall-count="servicesCount" :offset="servicesOffset" :limit="limit")


    .col-12.mt-2
      newsletter-collapse

    .col-12.mt-2
      .card
        .card-header.text-center
          a.p-1.h4(v-b-toggle="'lastOpenedHosts'") Zuletzt geöffnete Gastgeber
        b-collapse(id="lastOpenedHosts" v-model="areLastOpenedHostsVisible")
          .card-body(v-if="areLastOpenedHostsVisible")
            .table-responsive
              table.table.table-bordered
                thead
                  tr
                    th Id
                    th Name
                    th Ort
                    th Zuletzt geöffnet
                    th #
                tbody
                  tr(v-for="lastOpenedHost in lastOpenedHosts" :key="lastOpenedHost.Id")
                    td {{ lastOpenedHost.Id }}
                    td {{ lastOpenedHost.Name }}
                    td {{ lastOpenedHost.CityRegion }}
                    td {{ lastOpenedHost.LastOpenedAt | formatDateTime }}
                    td
                      router-link(:to="{ name: 'HostDetails', params: { hostId: lastOpenedHost.Id }, }")
                        button.btn-block.button.button-primary.button-tdays
                          | zum Gastgeber
            pagination(:overall-count="lastOpenedHostsCount" :offset="lastOpenedHostsOffset" :limit="limit")
    .col-12.mt-2.mb-4
      .card
        .card-header.text-center
          a.p-1.h4(v-b-toggle="'contracts'") Verträge
        b-collapse(id="contracts" v-model="areContractsVisible")
          .card-body(v-if="areContractsVisible")
            product-contracts(:user-id="$session.get('userId')")

</template>

<script>
import pagination from "./partials/pagination.vue";
import EventBus from "../event-bus";
import { PermissionsEnum } from "@/utils/enums/permissions/PermissionsEnum";
import { Hosts } from "../graphql/hosts/Hosts.ts";
import { Services } from "@/graphql/services/Services.ts";
import { Timebutler } from "@/graphql/timebutler/timebutler.ts";
import TotalSalesEvaluations from "@/views/evaluations/partials/TotalSalesEvaluations.vue";
import moment from "moment";
import CalendarPlugin from "@/views/calendar/Calendar.vue";
import NewsletterCollapseBody from "@/views/newsletters/NewsletterCollapseBody.vue";
import NewsletterCollapse from "@/views/newsletters/NewsletterCollapse.vue";
import { AppointmentsComponent } from "@/lib/components/appointments/AppointmentsComponent.ts";
import ServicesTable from "@/views/services/ServicesTable.vue";
import { ServiceStatusEnum } from "@/utils/enums/serviceStatus/ServiceStatusEnum.ts";
import ProductContracts from "@/views/productContracts/ProductContracts.vue";
import SessionsComponent from "@/lib/components/sessions/SessionsComponent";

export default {
  name: "Home",
  components: {
    ProductContracts,
    ServicesTable,
    NewsletterCollapse,
    NewsletterCollapseBody,
    CalendarPlugin,
    TotalSalesEvaluations,
    pagination,
  },
  data() {
    return {
      services: [],
      servicesCount: 0,
      serviceStatus: [ServiceStatusEnum.Draft],
      servicesOffset: 0,

      lastOpenedHosts: [],
      lastOpenedHostsCount: 0,
      lastOpenedHostsOffset: 0,

      limit: 15,

      permissions: this.$session.get("permissions"),
      PermissionsEnum: PermissionsEnum,
      ServiceStatusEnum: ServiceStatusEnum,

      moment: moment,

      calendarEntries: [],

      areDailySalesVisible: false,
      areContractsVisible: false,
      areLastOpenedHostsVisible: false,
    };
  },
  mounted() {
    const sessionsComponent = new SessionsComponent();
    sessionsComponent.handleQueryState(this);
    EventBus.$on(
      "updateDraftServicesList",
      function () {
        this.services = [];
        this.loadServices();
        this.loadServicesCount();
      }.bind(this)
    );
    this.loadServices();
    this.loadServicesCount();
    this.loadLastOpenedHosts();
    this.loadLastOpenedHostsCount();
    this.loadPublicHolidays();
    this.loadCalendarEntries();
    this.loadAppointments();
  },
  methods: {
    loadCalendarEntries() {
      try {
        this.$apollo
          .query({
            query: Timebutler.Queries.getAbsencesForCalendarView,
            fetchPolicy: "no-cache",
            variables: {
              year: moment().year(),
            },
          })
          .then(({ data }) => {
            if (!data || !data.Timebutler_getAbsences) {
              return;
            }

            for (const entry of data.Timebutler_getAbsences) {
              this.calendarEntries.push({
                id: entry.ID,
                startDate: moment(entry.From).format("YYYY-MM-DD HH:mm:ss"),
                endDate: moment(entry.To).format("YYYY-MM-DD HH:mm:ss"),
                title: `${entry.Type.Name} - ${entry.User.FirstName} ${entry.User.LastName}`,
                classes: [entry.State.toString(), entry.Type.Name.toString()],
                style: `background-color: ${entry.Type.Color};`,
                HalfADay: entry.HalfADay,
              });
            }
          })
          .catch((e) => {
            console.log(e);
            this.$alert(e.message);
          });
      } catch (e) {
        console.log(e);
        this.$alert(e.message);
      }
    },
    async loadAppointments() {
      try {
        const appointmentsComponent = new AppointmentsComponent();
        const appointments = await appointmentsComponent.getAppointments(
          this.$apollo,
          this.$session.get("userId"),
          this.$session.get("userId")
        );
        if (!appointments) {
          return;
        }
        for (const appointment of appointments) {
          this.calendarEntries.push({
            id: appointment.Id,
            startDate: moment(appointment.From).format("YYYY-MM-DD HH:mm:ss"),
            endDate: moment(
              appointment.To ? appointment.To : appointment.From
            ).format("YYYY-MM-DD HH:mm:ss"),
            title: appointment.Name,
            description: appointment.Description,
            classes: ["appointment"],
            style: `background-color: #4d6f95;`,
          });
        }
      } catch (e) {
        console.error(e);
        this.$alert(e.message);
      }
    },
    loadServices() {
      try {
        this.$apollo
          .query({
            query: Services.Queries.Services,
            fetchPolicy: "no-cache",
            variables: {
              serviceStatus: this.serviceStatus,
              offset: this.servicesOffset,
              limit: this.limit,
            },
          })
          .then(({ data }) => {
            if (!data || !data.services) {
              return;
            }
            this.services = data.services;
          })
          .catch((e) => {
            console.log(e);
            this.$alert(e.message);
          });
      } catch (e) {
        console.log(e);
        this.$alert(e.message);
      }
    },
    loadServicesCount() {
      try {
        this.$apollo
          .query({
            query: Services.Queries.ServicesCount,
            fetchPolicy: "no-cache",
            variables: {
              serviceStatus: this.serviceStatus,
            },
          })
          .then(({ data }) => {
            if (!data || data.servicesCount == undefined) {
              return;
            }
            this.servicesCount = data.servicesCount;
          });
      } catch (e) {
        console.log(e);
        this.$alert(e.message);
      }
    },
    loadLastOpenedHosts() {
      try {
        this.$apollo
          .query({
            query: Hosts.Queries.LastOpenedHosts,
            variables: {
              userId: this.$session.get("userId"),
              offset: this.lastOpenedHostsOffset,
              limit: this.limit,
            },
          })
          .then(({ data }) => {
            if (!data || !data.lastOpenedHosts) {
              return;
            }
            this.lastOpenedHosts = data.lastOpenedHosts;
          })
          .catch((e) => {
            console.log(e);
            this.$alert(e.message);
          });
      } catch (e) {
        console.log(e);
        this.$alert(e.message);
      }
    },
    loadLastOpenedHostsCount() {
      try {
        this.$apollo
          .query({
            query: Hosts.Queries.LastOpenedHostsCount,
            fetchPolicy: "no-cache",
            variables: {
              userId: this.$session.get("userId"),
            },
          })
          .then(({ data }) => {
            if (!data || data.lastOpenedHostsCount == undefined) {
              return;
            }
            this.lastOpenedHostsCount = data.lastOpenedHostsCount;
          });
      } catch (e) {
        console.log(e);
        this.$alert(e.message);
      }
    },
    loadPublicHolidays() {
      try {
        fetch("https://get.api-feiertage.de?states=nw")
          .then((response) => {
            return response.json();
          })
          .then((data) => {
            for (let holiday of data.feiertage) {
              this.calendarEntries.push({
                id: holiday.fname,
                startDate: moment(holiday.date).format("YYYY-MM-DD"),
                endDate: moment(holiday.date).format("YYYY-MM-DD"),
                title: holiday.fname,
                description: "Feiertag - NRW",
                classes: ["public-holiday"],
                style: `background-color: #999999;`,
              });
            }
          })
          .catch((reason) => {
            console.log(reason);
          });
      } catch (e) {
        console.log(e);
      }
    },
  },
};
</script>
