<template lang="pug">
.service
  .row
    .col-12.col-lg-2.side-nav.border-right
      ul.fix-nav.pt-2.pt-lg-3
        li
          .d-flex
            img(src="https://td-cloud.s3.eu-central-1.amazonaws.com/Images/Logos/touribook/branding/touribook-branding-50x50.png" style="height: 23px; width: 23px;")
            .h4.ml-1 Service

        li.mt-2
          p
            router-link(:to="{ name: 'OrdersList' }")
              font-awesome-icon.mr-2(:icon="['fas', 'cart-shopping']")
              span Käufe

        li
          p
            router-link(:to="{ name: 'TransactionsList' }")
              font-awesome-icon.mr-2(:icon="['fas', 'euro-sign']")
              span Transaktionen
        li
          p
            router-link(:to="{ name: 'VouchersList' }")
              font-awesome-icon.mr-2(:icon="['fas', 'file-pdf']")
              span Gutscheine
        li
          p
            router-link(:to="{ name: 'ShopVouchersList' }")
              font-awesome-icon.mr-2(:icon="['fas', 'file']")
              span Shop-Gutscheine
        li
          p
            router-link(:to="{ name: 'ServiceHostsEditingProgress' }")
              font-awesome-icon.mr-2(:icon="['fas', 'house']")
              span Gastgeber in Bearbeitung
        li
          p
            router-link(:to="{ name: 'SearchCriteriaAreas' }")
              font-awesome-icon.mr-2(:icon="['fas', 'list-check']")
              span Suchkriterienbereiche
        li
          p
            router-link(:to="{ name: 'SearchCriteriaSubAreas' }")
              font-awesome-icon.mr-2(:icon="['fas', 'list-check']")
              span Suchkriterienunterbereiche
        li
          p
            router-link(:to="{ name: 'ComplaintsList' }")
              font-awesome-icon.mr-2(:icon="['fas', 'circle-exclamation']")
              span Reklamationen

        li
          p
            router-link(:to="{ name: 'MailboxFolderMails', params: { mailboxId: 3, mailboxFolderId: MailboxFoldersEnum.Inbox } }")
              font-awesome-icon.mr-2(:icon="['fas', 'envelope']")
              span E-Mails

    .col-12.col-lg-10.pt-2.pb-5.mt-2.mt-sm-0
      router-view(:key="$route.fullPath")

</template>

<script>
import { MailboxFoldersEnum } from "@/utils/enums/mailboxFolders/MailboxFoldersEnum.ts";

export default {
  name: "Service",
  data() {
    return {
      MailboxFoldersEnum: MailboxFoldersEnum,
    };
  },
};
</script>
